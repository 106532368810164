import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        // ===== NAVBAR =====
        home: "Home",
        businessSetup: "Business Setup",
        ourServices: "Our Services",
        aboutUs: "About Us",
        Promotions: "Promotions",
        Blogs: "Blogs",
        logIn: "Log In",
        getInTouch: "Get In Touch",
        // ===== HOME =====
        milestones: "Milestones",
        "42+ Countries served": "42+ Countries served",
        inTheHeartof: "in the Heart of",
        empoweringYourBusinessFormation: "Empowering Your Business Formation",
        exploreOurServices: "Explore our services",
        whatSetsUsApart: "What sets us Apart",
        whatSetsUsApartDescription1: "What sets us apart is not just our professionalism, but our passion for excellence. We go beyond the conventional, ensuring that every step of your business journey is not merely completed but executed with exceptional finesse. SKV is synonymous with perfection, and our commitment to perfection extends to every facet of our services. Your business is not just another venture; it is a legacy in the making. We don't just build businesses; we forge enduring legacies.",
        whatSetsUsApartDescription2: "Join SKV Business Services, where your entrepreneurial aspirations are not only nurtured but elevated to unprecedented heights. Your journey to a better business is not a mere transaction; it's a collaborative effort to shape the future. Let's embark on this transformative journey together, shaping not just businesses but enduring legacies that stand the test of time.",
        // "Positioned at the crossroads of Asia, Europe, and Africa, the UAE offers easy access to international markets.": "Positioned at the crossroads of Asia, Europe, and Africa, the UAE offers easy access to international markets."
        // ===== ABOUT US =====
        about: "About",
        aboutSKV: "About SKV",
        aboutPara1:
          "SKV Business Services has been a pioneering force in the heart of Dubai's thriving business ecosystem. Since, 2007 we have been a guiding light for ambitious entrepreneurs navigating business establishments and expansions in the UAE.",
        aboutPara2:
          "At SKV, we are more than a service provider; we are your keystone on the exhilarating journey of entrepreneurship. Our masterful team, composed of seasoned UAE business setup experts, legal authorities, PRO experts, and company formation maestros, operates in harmony to meticulously shape the trajectory of your success.",
        aboutPara3:
          "What sets us apart is not just our professionalism, but our passion for excellence. We go beyond the conventional, ensuring that every step of your business journey is not merely completed but executed with exceptional finesse. SKV is synonymous with perfection, and our commitment to perfection extends to every facet of our services. Your business is not just another venture; it is a legacy in the making. We don't just build businesses; we forge enduring legacies. Join SKV Business Services, where your entrepreneurial aspirations are not only nurtured but elevated to unprecedented heights. Your journey to a better business is not a mere transaction; it's a collaborative effort to shape the future. Let's embark on this transformative journey together, shaping not just businesses but enduring legacies that stand the test of time.",
        "Build Better, Launch Faster": "Build Better, Launch Faster",
        "We're only just getting started on our journey":
          "We're only just getting started on our journey",
        "Total Businesses Setup": "Total Businesses Setup",
        "Our Mission & Vision": "Our Mission & Vision",
        mission: "Mission",
        missionText:
          "Empowering entrepreneurship success through customized solutions and integrated methodologies, our mission is to ensure unparalleled satisfaction and success in every venture we undertake.",
        vision: "Vision",
        visionText:
          "Our vision is to stand as the global leader in business setup services, dedicated to entrepreneurs. By delivering unparalleled expertise and unwavering support, we aim to guide visionaries through every stage, transforming their aspirations into flourishing, successful enterprises.",

        ourCollaborations: "Our Collaborations",
        "We have collaborations with multiple government agencies":
          "We have collaborations with multiple government agencies",

        chatWithProfessionals: "Chat with Professionals",
        chatWithProfessionalsText:
          "Connect with industry experts for real-time advice and consultation.",

        // ===== WHAT WE OFFER =====
        whatWeOffer: "What We Offer",
        "Your needs, our expertise. Find the perfect solution.":
          "Your needs, our expertise. Find the perfect solution.",
        // ===== WHY CHOOSE US =====
        whyChooseUs: "Why Choose Us",
        whyChooseUsDesc:
          "Join the thousands who have benefited from our innovative solutions and personalized support. Experience the commitment to quality that makes us leaders in our field.",
        winningPartnerships: "Winning Partnerships",
        winningPartnershipsText:
          "Lorem ipsum dolor sit amet, cadipiscing elit. Sed perspiatis and uta perspiciatis unde omnis iste nat voluptatem accusantium doloremque.",
        secureAndConfidential: "Secure & Confidential",
        secureAndConfidentialText:
          "Lorem ipsum dolor sit amet, cadipiscing elit. Sed perspiatis and uta perspiciatis unde omnis iste nat voluptatem accusantium doloremque.",
        fiveStarSupport: "5-Star Support",
        fiveStarSupportText:
          "Lorem ipsum dolor sit amet, cadipiscing elit. Sed perspiatis and uta perspiciatis unde omnis iste nat voluptatem accusantium doloremque.",

        // ===== FAQs =====
        FAQs: "FAQ's",
        FAQsDesc:
          "Everything you need to know about the services. Can't find the answer you're looking for? Please chat with our friendly team.",

        "Is there a free trial available?": "Is there a free trial available?",
        freeTrialAns:
          "Yes, you can try us for free for 30 days. If you want, we'll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",

        "Can I change my plan later?": "Can I change my plan later?",
        planChangeAns:
          "To buy a 3D model, search for the one you want and add it to your cart. Then, complete the checkout process and download the model",

        "What is your cancellation policy?":
          "What is your cancellation policy?",
        cancellationPolicyAns:
          "To create a seller account, go to znanye and visit the seller page. Register yourself by filling in the details.",

        "Can other info be added to an invoice?":
          "Can other info be added to an invoice?",
        invoiceInfoAns:
          "Firstly, you have to create your account on the website. You have to wait for some significant time for the verification. Once your account is verified, you can upload your 3D models",

        "How does billing work?": "How does billing work?",
        billingQueryAns: "We use PayPal and bank transfers to pay!",

        "How do I change my account email?":
          "How do I change my account email?",
        emailChangeAns:
          "You are paid when the model you uploaded sells! By the 5th of the following month, the payment will be credited directly to your linked account!",
        stillHaveQuestions: "Still have questions?",

        // ===== SUBSCRIBE =====
        join: "Join",
        subscribers: "Subscribers",
        subscribe: "Subscribe",
        "Stay in the loop with everything you need to know.":
          "Stay in the loop with everything you need to know.",
        "We care about your data in our privacy policy.":
          "We care about your data in our privacy policy.",

        // ===== SERVICES =====
        services: "Services",
        totalBusinessSetup: "Total business setup",
        businessSetupServices: "Business Setup Services",
        businessSetupServicesDesc:
          "Setting up a business in the UAE presents compelling advantages, including strategic location, stable economy, tax benefits, global connectivity, business-friendly policies, cultural diversity, legal framework, full ownership, quality of life, innovation hub and many more!",
        licenseServices: "License Services",
        licenseServicesDesc:
          "In Dubai, UAE, obtaining a Business Trade License is an indispensable requirement for legal operation. The process entails securing approvals from governmental and economic departments, along with the clearance of company formation documents from various UAE agencies.",
        publicRelationsOfficerPROServices:
          "Public Relations Officer (PRO) Services",
        proServices: "PRO Services",
        proServicesDesc:
          "Established with a steadfast commitment to simplifying PRO services in the UAE, SKV signifies excellence in facilitating seamless business setups. In a nation renowned for its investor-friendly environment, the process of launching a business requires meticulous attention to documentation and approvals.",
        amlRegistration: "AML Registration",
        goamlRegistration: "GoAML Registration",
        visaServices: "Visa Services",
        visaServicesDesc:
          "The Schengen Visa is a type of short-stay visa that allows travellers to visit within the Schengen Area, which comprises 26 European countries.",
        vatRegistration: "Vat Registration",
        corporateTaxRegistration: "Corporate Tax Registration",
        vatRegistrationAndCorporateTaxRegistration:
          "Vat Registration & Corporate Tax Registration",
        vatRegistrationAndCorporateTaxRegistrationDesc:
          "SKV Business Services has a reputable team of experienced and qualified accountants, auditors, and tax advisors based in Dubai, UAE. Our primary focus is to provide comprehensive VAT, tax management, and accounting services to businesses operating in Dubai and across the Emirates.",

        other: "Other",
        inUAE: "in UAE",
        "Ready to boost your business in Dubai? We're here to help":
          "Ready to boost your business in Dubai? We're here to help",

        // ===== SERVICES DETAILS =====
        // businessSetupServices
        UAEMainlandBusinessSetup: "UAE Mainland Business Setup",
        UAEFreezoneBusinessSetup: "UAE Freezone Business Setup",
        "Unlocking Business Potential with a UAE Mainland License":
          "Unlocking Business Potential with a UAE Mainland License",
        mainlandLicenseText:
          "A Mainland License in the UAE allows businesses versatile operation within the local jurisdiction, covering a broad spectrum of activities. It mandates a physical presence in the mainland, fostering direct market engagement. This license facilitates both local and international trade, offers access to government projects, and permits transactions in any currency. Overall, it ensures compliance with local regulations, fostering a professional business image in the UAE market.",

        // licenseServices
        accesstoLocalandGlobalmarkets:
          "Access to Local Market and Global markets",
        diversificationofRevenueStreams:
          "Diversification of Revenue Streams",
        accessToGovernmentIncentives: "Access to Government Incentives",
        accessToSkilledLabor: "Access to Skilled Labor",
        // proServices
        attestationServices: "Attestation Services",
        visaAndMedicalTyping: "Visa and Medical Typing",
        emiratesIDTyping: "Emirates ID Typing",

        // visaServices
        schengenVisa: "Schengen Visa",
        goldenVisa: "Golden Visa",

        // ===== SERVICES PAGES =====
        // businessSetupServices
        whyToChooseUAE: "Why to choose UAE",
        "Top Reasons to Choose the UAE for Your Business":
          "Top Reasons to Choose the UAE for Your Business",
        // "Discover how the UAE's strategic location, economic stability, and business-friendly environment make it a prime choice for global entrepreneurs.":
        whyToChooseDesc:
          "Discover how the UAE's strategic location, economic stability, and business-friendly environment make it a prime choice for global entrepreneurs.",

        strategicLocation: "Strategic Location",
        strategicLocationDesc:
          "Positioned at the crossroads of Asia, Europe, and Africa, the UAE offers easy access to international markets.",
        stableEconomy: "Stable Economy",
        stableEconomyDesc:
          "Known for its economic resilience and steady growth, offering a secure environment for investment.",

        taxBenefits: "Tax Benefits",
        taxBenefitsDesc:
          "Enjoy minimal tax liabilities with no personal income taxes and various exemptions for businesses.",

        globalConnectivity: "Global Connectivity",
        globalConnectivityDesc:
          "Exceptional air and sea connectivity makes global business operations smoother and more efficient.",

        qualityOfLife: "Quality of Life",
        qualityOfLifeDesc:
          "High standards of living with excellent healthcare, education, and recreational facilities.",

        culturalDiversity: "Cultural Diversity",
        culturalDiversityDesc:
          "A multicultural workforce enriches business perspectives and enhances competitive advantage.",

        legalFramework: "Legal Framework",
        legalFrameworkDesc:
          "Robust legal structures protect investments and simplify business operations.",

        fullOwnership: "Full Ownership",
        fullOwnershipDesc:
          "Foreign investors can fully own their businesses without the need for a local partner.",

        // from pdf
        whyUae: "Why UAE",
        businessSetupServicesPara1:
          "Setting up a business in the UAE presents compelling advantages, including strategic location, stable economy, tax benefits, global connectivity, business-friendly policies, cultural diversity, legal framework, full ownership, quality of life, innovation hub and many more!",
        businessSetupServicesPara2:
          "These factors collectively position the UAE as an enticing destination for entrepreneurs and businesses seeking growth, global connectivity, and a business-friendly environment.",
        businessSetupServicesPara3:
          "In today's dynamic global economy, the United Arab Emirates (UAE) stands at the forefront of unprecedented economic growth, facilitating substantial investments and fostering robust trade relations with esteemed partners. Renowned for its economic prosperity, the UAE presents an unparalleled opportunity for visionary entrepreneurs to embark on transformative business ventures.",
        businessSetupServicesPara4:
          "Navigating the process of business setup in the UAE may appear overwhelming to those unfamiliar with its regulatory landscape. However, before apprehensions obscure your ambitions, consider choosing SKV Business Services",
        businessSetupServicesPara5:
          "At SKV, we specialize in guiding aspiring entrepreneurs through every facet of establishing a business presence in the UAE. With our unwavering commitment to excellence and comprehensive support services, we demystify the complexities of business setup, empowering you to realize your entrepreneurial aspirations with confidence.",
        businessSetupServicesPara6:
          "Embrace the prospect of a prosperous business future in the UAE with SKV. Let us be your trusted partner on the journey to entrepreneurial success in one of the world's most dynamic business destinations. ",

        mainland: "Mainland",
        mainlandText:
          "A Mainland License in the UAE allows businesses versatile operation within the local jurisdiction, covering a broad spectrum of activities. It mandates a physical presence in the mainland, fostering direct market engagement. This license facilitates both local and international trade, offers access to government projects, and permits transactions in any currency. Overall, it ensures compliance with local regulations, fostering a professional business image in the UAE market.",
        comprehensiveServicesForMBS:
          "Comprehensive Services for Mainland Business Setup",
        // mainland visa reasons
        investorVisa: "Investor Visa",
        investorVisaText:
          "Allows investors to reside in the UAE and manage their business. It is typically valid for 2-3 years.",
        workVisa: "Work Visa",
        workVisaText:
          "Enables you and your employees to legally work in the UAE. This visa is essential for operational staff and management.",
        familyResidenceVisa: "Family Residence Visa",
        familyResidenceVisaText:
          "Permits family members of the investor to reside in the UAE. This visa is linked to the investor’s visa and is usually valid for the same duration.",
        businessLicense: "Licensing & Documentation",
        businessLicenseText:
          "Essential for operating legally, the license specifies the type of business activity and is required for all commercial operations.",
        labourCards: "Labour Cards",
        labourCardsText:
          "Issued by the Ministry of Human Resources and Emiratisation, this card is necessary for hiring employees and managing employment contracts.",
        emiratesId: "Emirates ID",
        emiratesIdText:
          "A mandatory identity card for all residents, including business owners and employees, used for various administrative and legal purposes.",
        governmentApprovals: "Government Approvals",
        governmentApprovalsText:
          " Required for the setup process, including approvals from relevant authorities for business activities and premises.",
        renewalOfBusinessLicense: "Renewal of Business License",
        renewalOfBusinessLicenseText:
          "Ensures your business remains compliant with local regulations by updating your license before its expiration.",

        stepsAndRequirements: "Steps & Requirements",
        chooseYourBusinessActivity: "Choose Your Business Activity",
        chooseYourBusinessActivityText:
          "Determine the specific activities your business will engage in. This is crucial as it will impact the type of license you need, and the approvals required. This picture but in different format and colour",
        LLCDubai: "LLC Dubai",

        selectALegalStructure: "Select a Legal Structure",
        selectALegalStructureText:
          "The most common legal structure for mainland businesses in the UAE is a Limited Liability Company (LLC).But there are various other options to:",
        differentFormatAndColor: "Different format and colour",
        reserveATradeName: "Reserve a Trade Name",
        reserveATradeNameText:
          "Choose a unique trade name for your business and submit it to the Department of Economic Development (DED) for approval. The name should be relevant to your business activity and comply with UAE naming conventions.",
        soleProprietorship: "Sole Proprietorship",
        civilCompany: "Civil Company",
        limitedLiabilityCompany: "Limited Liability Company",
        branchesAndRepresentationOffices: "Branches and Representation Offices",

        rentOfficeSpace: "Rent Office Space",
        rentOfficeSpaceText:
          "Rent physical office space in a location approved by the authorities. This office space must meet certain requirements and is mandatory for mainland businesses in the UAE.",
        obtainVisas: "Obtain Visas",
        obtainVisasText:
          "Applying for residence visas and work permits for oneself, partners, and any employees. This process involves medical tests, security clearances, and other formalities. Open a Corporate Bank Account: Open a corporate bank account with a UAE bank using the company's documents and residency visa. Banks may have specific requirements and documentation procedures.",
        ourServcesAndFaq: "Our Service and FAQ?",
        openACorporateBankAccount: "Open a Corporate Bank Account",
        openACorporateBankAccountText:
          "Open a corporate bank account with a UAE bank using the company's documents and residency visa. Banks may have specific requirements and documentation procedures.",
        manageYourTeamWithReports: "Manage your team with reports",
        manageYourTeamWithReportsText:
          "Measure what matters with Untitled's easy-to-use reports. You can filter, export, and drilldown on the data in a couple clicks.",

        freezone: "Freezone",
        freezoneText:
          "A Free Zone License in the UAE grants businesses the opportunity to operate within designated zones, offering distinct advantages such as tax exemptions, full ownership, and simplified processes. This license facilitates a streamlined business setup process and is particularly attractive for those seeking a business-friendly environment in the UAE.",
        // free zone business setup reasons
        noNeedForPhysicalPresence: "No need for physical pretense",
        noNeedForPhysicalPresenceText:
          "Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis.",
        limitedDirectinteractionWithGov:
          "Limited direct interaction with Govt.",
        limitedDirectinteractionWithGovText:
          "Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis.",
        exemptionFromVAT: "Exemption from value added tax (VAT)",
        exemptionFromVATText:
          "Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis.",
        corporateTaxExemption: "Corporate Tax Exemption",
        corporateTaxExemptionText:
          "Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis.",
        versatileBusinessType: "Versatile Business Type",
        versatileBusinessTypeText:
          "Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis.",
        stayInformedAboutChangesAndRegulations:
          "Stay informed by us about any changes in regulations or requirements that may affect your business operations.",
        "Ifza, Rak, Shams, Ajman, Dmcc, Dubai knowledge Village, Academic city, Maydaan":
          "Ifza, Rak, Shams, Ajman, Dmcc, Dubai knowledge Village, Academic city, Maydaan",
        "Need Form Here with all services option: Book now for expert guidance!":
          "Need Form Here with all services option: Book now for expert guidance!",
        "Unlocking UAE Freezone Business Setup":
          "Unlocking UAE Freezone Business Setup",
        yourPathwayToBusinessSuccess: "Your Pathway to Business Success",
        exploreStepsAndRequirementsText:
          "Explore the crucial steps and necessary requirements to successfully establish and launch your business.",
        comprehensiveServicesForFBS:
          "Comprehensive Services for Freezone Business Setup",
        comprehensiveServicesTextForMBS:
          "UAE Mainland business setup offers full market access, flexibility, and no restrictions on trade within the UAE or internationally.",
        comprehensiveServicesTextForFBS:
          "UAE Freezone business setup provides 100% foreign ownership, tax exemptions, and simplified processes within designated zones, ideal for international trade.",
        documents: "Documents",
        processDocuments: "Process Documents",
        letsStartWithDocumentation:
          "Let's get you started with the documentation process",
        procedureAndRequirement: "Procedure & Requirements",
        choosingTheRightFreeZone: "Choosing the Right Free Zone",
        choosingTheRightFreeZoneText:
          "Research different free zones in the UAE to find the one that best suits your business needs. Consider factors such as location, industry focus, facilities, and regulations.",
        selectingBusinessActivity: "Selecting Business Activity",
        selectingBusinessActivityText:
          "Once you have selected the freezone then determine the specific activities your business will engage in. Free zones typically have specific activities permitted within their jurisdiction, so ensure your chosen activities align with the free zone's regulations.",
        selectingCompanyName: "Selecting Company Name",
        selectingCompanyNameText:
          "Select a unique and relevant name for your company. Ensure it complies with the naming conventions of the chosen free zone.",
        submittingApplicationAndDocs: "Submitting Application and Documents",
        submittingApplicationAndDocsText:
          "Once you are done with the above process you will need to complete the application form and submit the documents which typically include:",
        submittingApplicationAndDocsPoint1:
          "Passport copies of shareholders and directors.",
        submittingApplicationAndDocsPoint2:
          "Proof of address for shareholders and directors.",
        submittingApplicationAndDocsPoint3:
          "Business plan outlining the company's activities and financial projections.",
        submittingApplicationAndDocsPoint4:
          "NOC (No Objection Certificate) if applicable.",
        submittingApplicationAndDocsPoint5:
          "Other specific documents as required by the free zone authority.",
        legalDocumentationAndLicenseApplication:
          "Legal Documentation and License Application",
        legalDocumentationAndLicenseApplicationText1:
          "Once your application is approved, you'll receive the legal documents from the free zone authority, including the Memorandum of Association (MOA) and Articles of Association (AOA). Review and sign these documents.",
        legalDocumentationAndLicenseApplicationText2:
          "Apply for the business license corresponding to your chosen activities.",
        legalDocumentationAndLicenseApplicationText3:
          "Free zones typically offer various types of licenses, such as trading, industrial, service, or professional licenses.",

        bankAccountOpening: "Bank Account Opening",
        bankAccountOpeningText:
          "Open a corporate bank account with a bank of your choice. Provide the bank with the necessary legal documents obtained from the free zone authority, including the license and lease agreement.",
        visaProcessing: "Visa Processing",
        visaProcessingText1:
          "Apply for residence visas and work permits for shareholders, directors, and employees if required. Each free zone has its own visa regulations and quotas",
        visaProcessingText2:
          "Submit the necessary documents, including passport copies, visa application forms, medical fitness certificates, and Emirates ID application forms. Compliance and Renewals - Ensure ongoing compliance with the regulations of the chosen free zone, including timely renewal of licenses, visas, and lease agreements.",
        complianceAndRenewals: "Compliance and Renewals",
        complianceAndRenewals1:
          "Ensure ongoing compliance with the regulations of the chosen free zone, including timely renewal of licenses, visas, and lease agreements.",
        complianceAndRenewals2:
          "Stay informed by us about any changes in regulations or requirements that may affect your business operations.",

        // licenseServices
        "Your Partner for Seamless Business Licensing in Dubai":
          "Your Partner for Seamless Business Licensing in Dubai",
        commercialLicense: "Commercial License",
        industrialLicense: "Industrial License",
        professionalLicense: "Professional License",
        licenseServicesPara1:
          "In Dubai, UAE, obtaining a Business Trade License is an indispensable requirement for legal operation. The process entails securing approvals from governmental and economic departments, along with the clearance of company formation documents from various UAE agencies.",
        licenseServicesPara2:
          "Amidst this complexity, SKV emerges as the quintessential partner for establishing and nurturing your business endeavours in Dubai. With an extensive experience and an unwavering dedication to fostering growth, we offer a comprehensive suite of services tailored to your specific needs. Our expertise extends beyond mere facilitation, as we provide guidance on selecting the most suitable license for your business venture. From acquisition to renewal, we oversee the entire licensing process, ensuring compliance and alleviating the burden from your shoulders.",
        licenseServicesPara3Title:
          "Empower your business aspirations with SKV, your steadfast partner in propelling your entrepreneurial journey towards success in the vibrant landscape of Dubai. Acquiring a mainland license in Dubai offers several distinct benefits for businesses:",
        "Access to Government Incentives": "Access to Government Incentives",
        "Access to Skilled Labor": "Access to Skilled Labor",
        "Requirements for obtaining a trade license":
          "Requirements for obtaining a trade license",
        "The process of obtaining a Business Trade License in Dubai involves several detailed steps.":
          "The process of obtaining a Business Trade License in Dubai involves several detailed steps.",
        initialConsultation: "Initial Consultation",
        initialConsultaionText:
          "SKV Business Services initiates the process with an in-depth consultation to understand your business needs, allowing us to provide tailored advice on the most suitable license for your operations.",
        documentationPreparation: "Documentation Preparation",
        documentPreperationText:
          "We guide you through the meticulous preparation of all necessary company formation documents. This includes legal paperwork, financial statements, and other essential documentation required for government and economic department approvals.",
        govAndEDA: "Government and Economic Department Approvals",
        govAndEDAText:
          "SKV facilitates the submission and processing of your documents with relevant government authorities and the economic department. Our expertise ensures a smooth approval process, minimizing delays and uncertainties.",
        licenseAcquisition: "License Acquisition",
        licenseAcquisitionText:
          "Once approvals are obtained, we assist you in acquiring the Business Trade License. This involves coordination with the relevant authorities to finalize the legal framework for your business operations.",
        ongoingSupport: "Ongoing Support",
        ongoingSupportText:
          "SKV Business Services doesn't just stop at license acquisition; we offer ongoing support and guidance, for your business growth in Dubai.",
        benefitOfChoosingSKVLicenseService:
          "By choosing SKV, you benefit from a comprehensive and detailed approach to business setup, alleviating the complexities involved and allowing you to focus on the success and expansion of your enterprise in Dubai.",
        // translationServices
        certifiedTranslators: "Certified Translators",
        certifiedTranslatorsText:
          "Our team comprises professional translators with specialized knowledge in legal terminology, ensuring accurate and contextually appropriate translations.",
        comprehensiveOffering: "Comprehensive Offering",
        comprehensiveOfferingText:
          "SKV caters to diverse business sectors, providing specialized translation services for a wide range of legal documents. This includes contracts, agreements, court documents, and academic writings.",
        accuracyAndPrecision: "Accuracy and Precision",
        accuracyAndPrecisionText:
          "We prioritize accuracy to prevent misinterpretations that could have legal implications. Thorough attention is given to maintain the integrity of the original legal content during translation.",
        confidentialityAndSecurity: "Confidentiality and Security",
        confidentialityAndSecurityText:
          "SKV places a high premium on the confidentiality of legal documents. Stringent security measures are in place to protect sensitive information during the translation process.",
        timelyDelivery: "Timely Delivery",
        timelyDeliveryText:
          "Recognizing the importance of timelines in legal matters, SKV commits to timely delivery without compromising on the quality of translations. By choosing SKV, clients benefit from a meticulous and detail-oriented approach to legal translation, ensuring precise and legally translated documents.",
        benefitOfChoosingSKVTranslationService:
          "By choosing SKV, clients benefit from a meticulous and detail-oriented approach to legal translation, ensuring precise and legally translated documents",
        "Need Form Here with all service option: Book now for expert guidance!":
          "Need Form Here with all service option: Book now for expert guidance!",

        // proServices
        proServicesPara1:
          "Established with a steadfast commitment to simplifying PRO services in the UAE, SKV signifies excellence in facilitating seamless business setups. In a nation renowned for its investor-friendly environment, the process of launching a business requires meticulous attention to documentation and approvals. Recognizing the complexity inherent in this endeavour, SKV offers a comprehensive solution by extending our expertise as your dedicated PRO agent.",
        proServicesPara2:
          "Our comprehensive range of services spans every aspect of business establishment, encompassing company formation, licensing, emirates id, power of attorney, certificate attestation and an array of PR-related tasks. Understanding that each business possesses its own distinct requirements, we approach our PRO services in the UAE with a mindset, ensuring that every aspect is fully compliant with prevailing legal regulations.",
        whatAreProServicesInDubai: "What are PRO Services in Dubai?",
        proServicesAns1:
          "A Public Relations Officer (PRO) assumes a critical role in the administrative activities for the successful initiation and sustained operation of businesses.",
        proServicesAns2:
          "From inception to operation, the PRO serves as a guide, navigating clients through the procedures mandated by the regulatory bodies. Their comprehensive support extends to facilitating essential tasks such as license renewals, visa services, labour agreements, and much more. Drawing upon their profound understanding of UAE regulations and protocols, the PRO ensures that each step in the process is executed as per legal compliance.",
        proServicesAns3:
          "Beyond the initial setup phase, the PRO remains a dedicated partner, committed to ensuring the seamless continuity of business activities.",
        // some of pro services
        labourCardRenewal: "Labour card renewal",
        licenseServices: "License Services",
        translationServices: "Translation Services",
        attestationServices: "Attestation Services",
        visaAndMedicalTyping: "Visa and Medical Typing",
        SIRAServices: "SIRA Services",
        emiratesIDTyping: "Emirates ID Typing",
        powerOfAttorney: "Power of Attorney",
        localSponsorship: "Local Sponsorship",
        AmerAndTasheelServices: "Amer and Tasheel Services",
        AML: "AML",

        // visaServices
        chooseYourVisa: "Choose your visa",
        "30 Days Single entry, 60 days Single entry, 90 Days Single entry":
          "30 Days Single entry, 60 days Single entry, 90 Days Single entry",
        "30 Days Multi entry, 60 Days Multi entry, 5 years Multi - Visa, Extension Visa":
          "30 Days Multi entry, 60 Days Multi entry, 5 years Multi - Visa, Extension Visa",
        "This Format except for the prices and visas which are written above":
          "This Format except for the prices and visas which are written above",

        schengenVisa: "Schengen Visa",
        schengenVisaPara1:
          "The Schengen Visa is a type of short-stay visa that allows travellers to visit within the Schengen Area, which comprises 26 European countries.",
        eligibility: "Eligibility",
        schengenVisaEligibilityText:
          "Citizens of certain countries are required to obtain a Schengen Visa before entering the Schengen Area. The eligibility criteria may vary depending on the applicant's nationality, purpose of travel, and other factors.",
        schengenVisaPara2:
          "Applicants must demonstrate the intention to leave the Schengen Area before the visa expires and provide evidence of sufficient funds to cover their stay.",
        typesOfSchengenVisa: "Types of Schengen Visas",
        uniformSchengenVisa: "Uniform Schengen Visas (USV)",
        uniformSchengenVisaText:
          "These are short-stay visas allowing stays of up to 90 days within a 180-day period.",
        "USVs are further categorized into":
          "USVs are further categorized into",
        singleEntryVisa: "Single-entry Visa",
        singleEntryVisaText: "Allows entry into the Schengen Area once.",
        doubleEntryVisa: "Double-entry Visa",
        doubleEntryVisaText:
          "Permits two entries into the Schengen Area within the visa's validity period.",
        multipleEntryVisa: "Multiple-entry Visa",
        multipleEntryVisaText:
          "Allows multiple entries into the Schengen Area during the visa's validity period.",
        applicationProcess: "Application Process",
        applicationProcessText1:
          "The application process typically involves completing an application form, providing supporting documents (such as passport, travel itinerary, accommodation bookings, proof of financial means, travel insurance, and visa photos), and attending a visa interview if required.",
        applicationProcessText2:
          "Applicants may also need to pay a non-refundable visa application fee.",
        processingTime: "Processing Time",
        processingTimeText1:
          "The processing time for Schengen Visa applications varies depending on the consulate or embassy and the volume of applications received. It can range from a few days to several weeks.",
        processingTimeText2:
          "Applicants are advised to apply for the visa well in advance of their planned travel dates to allow for sufficient processing time.",
        schengenAreaCountries: "Schengen Area Countries",
        schengenAreaCountriesText:
          "The Schengen Area comprises 22 EU member states and four non-EU member states: Austria, Belgium, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Iceland, Italy, Latvia, Liechtenstein, Lithuania, Luxembourg, Malta, Netherlands, Norway, Poland, Portugal, Slovakia, Slovenia, Spain, Sweden, and Switzerland.",

        goldenVisa: "Golden Visa",
        goldenVisaPara1:
          "The Dubai Golden Visa program is a prestigious initiative offered by the United Arab Emirates (UAE) government, specifically tailored to provide long-term residency to expatriates. Under this program, eligible individuals can obtain a residency visa that grants them the right to reside, work, study, or conduct business in Dubai for an extended period, up to 10 years.",
        keyFeaturesAndBenefits: "Key Features and Benefits",
        longTermResidency: "Long-Term Residency",
        longTermResidencyText:
          "Holders of the Dubai Golden Visa enjoy the privilege of residing in the Emirate for a duration of up to 10 years, providing stability and security for themselves and their families.",
        freedomToWorkStudy: "Freedom to Work, Study, and Conduct Business",
        freedomToWorkStudyText:
          "Golden Visa holders have the flexibility to pursue employment opportunities, pursue higher education, or establish and manage their businesses in Dubai without the need for a local Emirati sponsor.",
        multiLevelPermit: "Multi-Entry Permit",
        multiLevelPermitText:
          "Golden Visa holders benefit from a multi-entry permit to the UAE, facilitating seamless travel in and out of the country for business, leisure, or personal reasons.",
        eligibilityCriteria: "Eligibility Criteria",
        eligibilityCriteriaText:
          "The Golden Visa program in Dubai is open to select categories of individuals, including investors, entrepreneurs, executives, professionals, and outstanding students, among others. Eligibility criteria may vary based on the applicant's qualifications, achievements, and contribution potential. Below are a few categories:",
        documentationRequirements: "Documentation Requirements",
        documentationRequirementsText:
          "Applicants must submit a comprehensive set of documents, including but not limited to passport copies, proof of financial stability, investment records, educational qualifications.",
        whyChooseSKVForGoldenVisa: "Why Choose SKV for Golden Visa Services?",
        whyChooseSKVForGoldenVisaText:
          "SKV is a trusted provider of comprehensive Golden Visa services, offering expert guidance and support to individuals seeking to obtain long-term residency in Dubai. Our team of experienced professionals assists clients at every stage of the application process, from initial consultation to document preparation and submission, ensuring a seamless and hassle-free experience.",
        benefitOfChoosingSKVVisaService: "With SKV, clients can benefit from",
        persionalizedConsultaion: "Personalized Consultation",
        persionalizedConsultaionText:
          "Our experts provide tailored guidance based on each client's unique circumstances, ensuring informed decision-making and optimal outcomes.",
        streamlinedProcess: "Streamlined Process",
        streamlinedProcessText:
          "We handle all aspects of the Golden Visa application process, leveraging our extensive experience and knowledge of UAE immigration regulations to expedite approvals and minimize delays.",
        complianceAssurance: "Compliance Assurance",
        complianceAssuranceText:
          "SKV ensures strict adherence to regulatory requirements and documentation standards, mitigating risks and ensuring the success of our clients' Golden Visa applications",

        // vatRegistrationAndCorporateTaxRegistration
        vatRegAndCTRPara1:
          "SKV Business Services has a reputable team of experienced and qualified accountants, auditors, and tax advisors based in Dubai, UAE. Our primary focus is to provide comprehensive VAT, tax management, and accounting services to businesses operating in Dubai and across the Emirates. We pride ourselves on maintaining strong relationships with officials in the ministry, enabling us to stay abreast of evolving business laws and UAE taxation regulations. This strategic advantage allows us to anticipate and prepare our clients for any potential impacts on their businesses stemming from changes in the economy or tax landscape.",
        vatRegAndCTRPara2:
          "Our team of dedicated tax consultants in Dubai assesses each client's unique circumstances to develop tailored tax planning strategies and ensure compliance with regulatory requirements. We meticulously maintain your company's financial records, enabling you to accurately track your financial performance and fulfil your reporting obligations.",
        "At SKV Tax Consultants and Accountants, we offer a wide range of services aimed at assisting businesses of all sizes and industries, including but not limited to:":
          "At SKV Tax Consultants and Accountants, we offer a wide range of services aimed at assisting businesses of all sizes and industries, including but not limited to:",
        vatRegAndCTRPoint1:
          "Recording and maintaining your company's financial records",
        vatRegAndCTRPoint2:
          "Auditing financial statements to ensure accuracy and compliance",
        vatRegAndCTRPoint3:
          "Providing support in investment banking activities",
        vatRegAndCTRPoint4:
          "Keeping clients informed about tax litigation and regulatory policies",
        vatRegAndCTRPoint:
          "Offering personalized consultation to optimize tax efficiency and enhance overall financial productivity",
        vatRegAndCTRPara3:
          " Our commitment to delivering exceptional service goes beyond mere compliance; we strive to empower our clients with valuable insights and opportunities to improve their financial efficiency and achieve sustainable growth. By leveraging our expertise and resources, we create endless possibilities for our clients to navigate the complexities of UAE taxation while maximizing their business potential.",
        vatRegAndCTRPara4:
          "Partner with SKV Business Services today and experience the difference our dedicated team can make in optimizing your tax and accounting processes, driving your business forward with confidence and success.",

        // ===== BLOGS =====
        ourBlogs: "Our Blogs",
        resourcesAndInsights: "Resources and insights",
        theLatestIndustryNewsTechEtcText:
          "The latest industry news, interviews, technologies, and resources.",
        search: "Search",
        newestFirst: "Newest First",
        oldestFirst: "Oldest First",
        published: "Published",
        latestBlogs: "Latest Blogs",
        "Interviews, tips, guides, industry best practices, and news.":
          "Interviews, tips, guides, industry best practices, and news.",
        viewAllBlogs: "View all blogs",
        design: "Design",
        fromTheBlog: "From the blog",

        loadMore: "Load more",
        copyLink: "Copy Link",

        blogsNotFound:
          "Blogs not found or there is some issue in getting blogs !",
        noBlogsMatching: "No blogs matching with the search text !",

        // ===== CONTACT US =====
        "Let's level up your brand, together in Dubai":
          "Let's level up your brand, together in Dubai",
        "Our friendly team would love to hear from you.":
          "Our friendly team would love to hear from you.",
        "Our friendly team is here to help.":
          "Our friendly team is here to help.",
        office: "Office",
        "Come say hello at our office HQ.": "Come say hello at our office HQ.",
        mondayToFriday: "Mon-Fri",
        from: "From",
        callTimingText: "Mon-Fri from 8am to 5pm.",
        knowMore: "Know More",
        message: "Message",
        call: "Call",
        mail: "Mail",

        // ===== FORMS =====
        send: "Send",
        submit: "Submit",
        upload: "Upload",
        saveChanges: "Save Changes",
        verify: "Verify",
        reset: "Reset",
        name: "Name",
        enterYourName: "Enter Your Name",
        fullName: "Full Name",
        enterYourFullName: "Enter Your Full Name",
        email: "Email",
        enterYourEmail: "Enter Your Email",
        phone: "Phone",
        enterYourPhone: "Enter Your Phone",
        mobile: "Mobile",
        enterYourMobile: "Enter Your Mobile",
        password: "Password",
        enterPassword: "Enter Password",
        confirmPassword: "Confirm Password",
        enteConfirmPassword: "Enter Confirm Password",
        currentPassword: "Current Password",
        newPassword: "New Password",
        confirmNewPassword: "Confirm New Password",
        country: "Country",
        enterYourcountry: "Enter Your Country",
        chooseCountry: "Choose country",
        city: "City",
        chooseCity: "Choose City",
        enterYourcity: "Enter Your City",
        office: "Office",
        address: "Address",
        addYourFullAddress: "Add your full address",
        enterYouraddress: "Enter Your Address",
        subject: "Subject",
        enterSubject: "Enter Subject",
        yourRequirement: "Your requirement",
        enterMessage: "Enter Message",
        yourRequirement: "Your requirement",
        agreePrivacyPolicy: "Agree to our friendly privacy policy",
        iAgreeToTermsAndConditions: "I Agree to the Terms and Conditions.",
        note: "Note",
        "Document will be shared by given address or register email id":
          "Document will be shared by given address or register email id",
        cancel: "Cancel",
        "Please enter your current password to change your password.":
          "Please enter your current password to change your password.",
        "Your new password must have more than 8 characters":
          "Your new password must have more than 8 characters",
        "Your new password must be different to previously used passwords.":
          "Your new password must be different to previously used passwords.",
        reseting: "Reseting",
        resetPassword: "Reset Password",
        leaveUsAMessage: "Leave us a message",
        "You agree to our friendly privacy policy.":
          "You agree to our friendly privacy policy.",
        docsAgreeText: "I agree that I have the above mentioned documents.",
        resendOtp: "Resend OTP",

        verifyEmail: "Verify Email!",
        backToLogin: "Back to Log In",
        pleaseEnterOtpAndVerify: "Please enter otp and verify.",
        accountVerified: "Account verified?",
        forgotPassword: "Forgot Password?",

        john: "John",
        doe: "Doe",
        creating: "Creating",
        sending: "Sending",
        resetting: "Resetting",
        signingUp: "Signing up",
        signingIn: "Signing in",
        signUp: "Sign Up",
        signIn: "Sign In",
        signOut: "Sign Out",
        setNewPassword: "Set New Password!",
        alreadyHaveAnAccount: "Already have an account?",
        letsStart: "Let's Start!",
        pleaseEnterYourDetails: "Please enter your details.",
        selectLanguage: "Select Language",
        connectOnWhatsapp: "Connect on WhatsApp",
        connectOverCall: "Connect over Call",
        dontHaveAnAccount: "Dont have an account?",
        sendOtp: "Send OTP",
        back: "Back",

        // ===== FOOTER QUICK LINKS =====
        quickLinks: "Quick Links",
        ourServices: "Our Services",
        promotions: "Promotions",
        blogs: "Blogs",
        contactUs: "Contact Us",
        "UAE Free zone Business Setup": "UAE Free zone Business Setup",
        commercial: "Commercial",
        professional: "Professional",
        industrial: "Industrial",
        privacyPolicy: "Privacy Policy",
        termsOfService: "Terms of Service",
        cookiePolicy: "Cookie Policy",
        allRightsReserved: "All rights reserved",
        location: "Location",
        linkedIn: "LinkedIn",
        twitter: "Twitter",
        youtube: "Youtube",
        facebook: "Facebook",
        allRightsReserved: "All rights reserved.",

        // ===== BRANCHES =====
        ourBranches: "Our Branches",
        headOffice: "HEAD OFFICE",
        branchOffice: "Branch Office",
        dubai: "Dubai",
        UAE: "UAE",
        unitedArabEmirates: "United Arab Emirates",
        "Gold souq deira dubai, UAE": "Gold souq deira dubai, UAE",
        UK: "UK",
        unitedKingdom: "United Kingdom",
        "1st Floor 124 Cleveland Street, London, UK":
          "1st Floor 124 Cleveland Street, London, UK",
        hungary: "Hungary",
        "2073 Tök, Fő utca 1":
          "2073 Tök, Fő utca 1",

        // ===== PROMOTIONS =====
        promotions: "Promotions",
        findOurOngoingPromotions: "Find Our Ongoing Promotions",
        findOurOngoingPromotionsText:
          "Mi tincidunt elit, id quisque ligula ac diam, amet.",
        promotions1: "Ipsum sit mattis nulla",
        promotions1Text:
          "Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend",

        // ===== ACCOUNT =====
        goToServices: "Go To Services",
        chatToSales: "Chat To Sales",
        trackYourService: "Track Your Service",
        trackYourProgress: "Track your Progress",
        noServicesYet: "No Services yet!",
        plan: "Plan",
        documentStatus: "Document Status",
        paymentStatus: "Payment Status",
        servicesStatus: "Services Status",
        documentsReceived: "Documents Received",
        receivedOnline: "Received On",
        verification: "Verification",
        verified: "Verified",
        verifiedOn: "Verified On",
        delivery: "Delivery",
        expectedOn: "Expected on",

        basicDetails: "Basic Details",
        paymentHistory: "Payment History",
        changePassword: "Change Password",
        settings: "Settings",
        login: "Login",
        loggingIn: "Logging in",
        logout: "Logout",
        account: "Account",
        loginWithGoogle: "Log in with Google",

        // ===== ORDER =====
        id: "ID",
        allOrders: "All Orders",
        goToOrder: "Go To Order",
        orderAlreadyCreated: "Order Already Created",

        // ===== BUY PLAN =====
        buyPlan: "Buy Plan",
        process: "Process",
        submittedBeingReviewed: "SUBMITTED - Being Reviewed",
        docsBeingReviewed: "Your documents are being reviewed!",
        allDocsBeingReviewed: "All your documents are reviewed",
        pleaseProceedToCheckout: "Please proceed to checkout",
        proceed: "Proceed",
        serviceDetails: "Service Details",
        basicDetails: "Basic Details",
        nameAndDetails: "Name & Details",
        docUpload: "Document Upload",
        requiredDocs: "Required Documents",
        documentsListRequired: "Documents List Required",
        // ===== BILLING ======
        billing: "Billing",
        "Manage your billing and payment details.":
          "Manage your billing and payment details.",
        pendingAmount: "Pending Amount",
        "Document will be shared only after remaining payment done":
          "Document will be shared only after remaining payment done",
        payRemainingAmount: "Pay Remaining Amount",
        billingAndInvoicing: "Billing and Invoicing",
        "Pick an account plan that fits your workflow.":
          "Pick an account plan that fits your workflow.",
        invoice: "Invoice",
        billingDate: "Billing Date",
        status: "Status",
        amount: "Amount",
        action: "Action",
        checkout: "Checkout",
        or: "Or",
        createAnAccount: "Create an Account",

        // ===== TOASTS =====
        passwordsDidNotMatch: "Passwords did not match",
        accountCreatedSuccessfully: "Account created successfully",
        pleaseEnterValidData: "Please enter the valid data!",
        problemWhileSigningUp: "There was some problem while signing up!",
        docsUploadedSuccessfully: "Documents uploaded successfully!",
        pleaseUploadAllFiles: "Please upload files for all document types.",
        pleaseAgreeToTermsAndConditions:
          "Please agree to the terms and conditions",
        pleaseEnterAllTheValues: "Please enter all the values",
        "No worries, we'll send you reset instructions.":
          "No worries, we'll send you reset instructions.",
        errorWhileSendingOtp: "Error while sending OTP, Try again later",
        pleaseEnterYourEmail: "Please enter the email.",
        // ===== ALERTS =====
        docsUploadedSuccessfully: "Documents uploaded successfully!",
        welcomeBack: "Welcome Back!",
      },
    },
    fr: {
      translation: {
        home: "Accueil",
        businessSetup: "Création d'entreprise",
        ourServices: "Nos services",
        aboutUs: "À propos de nous",
        aboutSKV: "À propos de skv",
        Promotions: "Promotions",
        Blogs: "Blogs",
        logIn: "Se connecter",
        getInTouch: "Contactez-nous",
        // ===== HOME =====
        milestones: "Étapes importantes",
        "42+ Countries served": "42+ pays desservis",
        inTheHeartof: "Au coeur de",
        empoweringYourBusinessFormation: "Renforcer la création de votre entreprise",
        exploreOurServices: "Découvrez nos services",
        whatSetsUsApart: "Ce qui nous distingue",
        whatSetsUsApartDescription1: "Ce qui nous distingue n'est pas seulement notre professionnalisme, mais notre passion pour l'excellence. Nous allons au-delà du conventionnel, en veillant à ce que chaque étape de votre parcours professionnel soit non seulement complétée mais exécutée avec une finesse exceptionnelle. SKV est synonyme de perfection et notre engagement envers la perfection s'étend à toutes les facettes de nos services. Votre entreprise n'est pas simplement une autre entreprise ; c’est un héritage en devenir. Nous ne construisons pas seulement des entreprises ; nous forgeons un héritage durable.",
        whatSetsUsApartDescription2: "Rejoignez SKV Business Services, où vos aspirations entrepreneuriales sont non seulement nourries mais élevées à des sommets sans précédent. Votre parcours vers une meilleure entreprise n’est pas une simple transaction ; c'est un effort de collaboration pour façonner l'avenir. Embarquons ensemble dans ce voyage de transformation, en façonnant non seulement des entreprises, mais aussi un héritage durable qui résiste à l'épreuve du temps.",
        // "Positioned at the crossroads of Asia, Europe, and Africa, the UAE offers easy access to international markets.": "Positionné au carrefour de l'Asie, de l'Europe et de l'Afrique, les Émirats offrent un accès facile aux marchés internationaux."
        // ===== ABOUT US =====
        about: "À propos",
        aboutPara1:
          "SKV Business Services est une force pionnière au cœur de l'écosystème d'affaires florissant de Dubaï. Depuis 2007, nous sommes une lumière guide pour les entrepreneurs ambitieux naviguant dans les établissements et expansions d'affaires aux Émirats.",
        aboutPara2:
          "Chez SKV, nous sommes plus qu'un prestataire de services ; nous sommes votre pierre angulaire sur le chemin exaltant de l'entrepreneuriat. Notre équipe experte, composée de spécialistes chevronnés de la création d'entreprise aux Émirats, d'autorités juridiques, d'experts PRO et de maîtres en formation d'entreprise, opère en harmonie pour façonner méticuleusement la trajectoire de votre succès.",
        aboutPara3:
          "Ce qui nous distingue n'est pas seulement notre professionnalisme, mais notre passion pour l'excellence. Nous allons au-delà du conventionnel, assurant que chaque étape de votre parcours entrepreneurial n'est pas seulement achevée mais exécutée avec une finesse exceptionnelle. SKV est synonyme de perfection, et notre engagement envers la perfection s'étend à chaque facette de nos services. Votre entreprise n'est pas simplement une autre entreprise ; c'est un héritage en devenir. Nous ne construisons pas seulement des entreprises ; nous forgeons des héritages durables. Rejoignez SKV Business Services, où vos aspirations entrepreneuriales ne sont pas seulement nourries mais élevées à des hauteurs sans précédent. Votre chemin vers une meilleure entreprise n'est pas une simple transaction ; c'est un effort collaboratif pour façonner l'avenir. Embarquons ensemble dans ce voyage transformateur, en façonnant non seulement des entreprises mais des héritages durables qui résistent à l'épreuve du temps.",
        "Build Better, Launch Faster": "Construisez mieux, lancez plus vite",
        "We're only just getting started on our journey":
          "Nous ne faisons que commencer notre voyage",
        "Total Businesses Setup": "Total des entreprises créées",
        "Our Mission & Vision": "Notre mission et vision",
        mission: "Mission",
        missionText:
          "Favoriser le succès entrepreneurial grâce à des solutions personnalisées et des méthodologies intégrées, notre mission est d'assurer une satisfaction et un succès incomparables dans chaque entreprise que nous entreprenons.",
        vision: "Vision",
        visionText:
          "Notre vision est de nous positionner en tant que leader mondial des services de création d'entreprise, dédiés aux entrepreneurs. En offrant une expertise inégalée et un soutien indéfectible, nous visons à guider les visionnaires à travers chaque étape, transformant leurs aspirations en entreprises florissantes et prospères.",
        ourCollaborations: "Nos collaborations",
        "We have collaborations with multiple government agencies":
          "Nous avons des collaborations avec plusieurs agences gouvernementales",
        chatWithProfessionals: "Discutez avec des professionnels",
        chatWithProfessionalsText:
          "Connectez-vous avec des experts du secteur pour des conseils et consultations en temps réel.",
        // ===== WHAT WE OFFER =====
        whatWeOffer: "Ce que nous offrons",
        "Your needs, our expertise. Find the perfect solution.":
          "Vos besoins, notre expertise. Trouvez la solution parfaite.",
        // ===== WHY CHOOSE US =====
        whyChooseUs: "Pourquoi nous choisir",
        whyChooseUsDesc:
          "Rejoignez les milliers de personnes qui ont bénéficié de nos solutions innovantes et de notre soutien personnalisé. Découvrez l'engagement envers la qualité qui fait de nous des leaders dans notre domaine.",
        winningPartnerships: "Partenariats gagnants",
        winningPartnershipsText:
          "Lorem ipsum dolor sit amet, cadipiscing elit. Sed perspiatis and uta perspiciatis unde omnis iste nat voluptatem accusantium doloremque.",
        secureAndConfidential: "Sécurisé et confidentiel",
        secureAndConfidentialText:
          "Lorem ipsum dolor sit amet, cadipiscing elit. Sed perspiatis and uta perspiciatis unde omnis iste nat voluptatem accusantium doloremque.",
        fiveStarSupport: "Support 5 étoiles",
        fiveStarSupportText:
          "Lorem ipsum dolor sit amet, cadipiscing elit. Sed perspiatis and uta perspiciatis unde omnis iste nat voluptatem accusantium doloremque.",
        // ===== FAQs =====
        FAQs: "FAQ",
        FAQsDesc:
          "Voici tout ce que vous devez savoir sur les services. Vous ne trouvez pas la réponse que vous cherchez ? Veuillez discuter avec notre équipe sympathique.",
        "Is there a free trial available?":
          "Y a-t-il un essai gratuit disponible ?",
        freeTrialAns:
          "Oui, vous pouvez nous essayer gratuitement pendant 30 jours. Si vous le souhaitez, nous vous fournirons un appel de prise en main personnalisé gratuit de 30 minutes pour vous mettre en route dès que possible.",
        "Can I change my plan later?": "Puis-je changer mon plan plus tard ?",
        planChangeAns:
          "Pour acheter un modèle 3D, recherchez celui que vous souhaitez et ajoutez-le à votre panier. Ensuite, complétez le processus de paiement et téléchargez le modèle.",
        "What is your cancellation policy?":
          "Quelle est votre politique d'annulation ?",
        cancellationPolicyAns:
          "Pour créer un compte vendeur, allez sur znanye et visitez la page du vendeur. Inscrivez-vous en remplissant les détails.",
        "Can other info be added to an invoice?":
          "D'autres informations peuvent-elles être ajoutées à une facture ?",
        invoiceInfoAns:
          "Tout d'abord, vous devez créer votre compte sur le site. Vous devez attendre un certain temps pour la vérification. Une fois votre compte vérifié, vous pouvez télécharger vos modèles 3D.",
        "How does billing work?": "Comment fonctionne la facturation ?",
        billingQueryAns:
          "Nous utilisons PayPal et les virements bancaires pour payer !",
        "How do I change my account email?":
          "Comment changer mon email de compte ?",
        emailChangeAns:
          "Vous êtes payé lorsque le modèle que vous avez téléchargé est vendu ! Avant le 5 du mois suivant, le paiement sera crédité directement sur votre compte lié.",
        stillHaveQuestions: "Vous avez encore des questions ?",
        // ===== SOUSCRIRE =====
        join: "Rejoindre",
        subscribers: "Abonnés",
        subscribe: "S'abonner",
        "Stay in the loop with everything you need to know.":
          "Restez au courant de tout ce que vous devez savoir.",
        "We care about your data in our privacy policy.":
          "Nous nous soucions de vos données dans notre politique de confidentialité.",
        // ===== SERVICES =====
        services: "Services",
        totalBusinessSetup: "Configuration totale de l'entreprise",
        businessSetupServices: "Services de création d'entreprise",
        businessSetupServicesDesc:
          "La création d'une entreprise aux EAU présente des avantages convaincants, notamment une position stratégique, une économie stable, des avantages fiscaux, une connectivité mondiale, des politiques favorables aux entreprises, une diversité culturelle, un cadre juridique, une pleine propriété, une qualité de vie, un hub d'innovation et bien plus encore!",
        licenseServices: "Services de licence",
        licenseServicesDesc:
          "À Dubaï, aux EAU, l'obtention d'une licence commerciale est une exigence indispensable pour une exploitation légale. Le processus implique d'obtenir des approbations des départements gouvernementaux et économiques, ainsi que le dédouanement des documents de formation de société auprès de diverses agences des EAU.",
        publicRelationsOfficerPROServices:
          "Services d'agent de relations publiques (PRO)",
        proServices: "PRO",
        proServicesDesc:
          "Établie avec un engagement ferme à simplifier les services PRO aux EAU, SKV signifie l'excellence en facilitant des installations commerciales sans couture. Dans une nation réputée pour son environnement favorable aux investisseurs, le processus de création d'une entreprise nécessite une attention méticuleuse à la documentation et aux approbations.",
        amlRegistration: "Enregistrement AML",
        goamlRegistration: "Inscription GoAML",
        visaServices: "Services de visa",
        visaServicesDesc:
          "Le visa Schengen est un type de visa de courte durée qui permet aux voyageurs de visiter l'espace Schengen, composé de 26 pays européens.",
        vatRegistration: "Enregistrement TVA",
        corporateTaxRegistration: "Enregistrement de l'impôt sur les sociétés",
        vatRegistrationAndCorporateTaxRegistration:
          "Enregistrement TVA & Enregistrement de l'impôt sur les sociétés",
        vatRegistrationAndCorporateTaxRegistrationDesc:
          "SKV Business Services dispose d'une équipe réputée de comptables, auditeurs et conseillers fiscaux expérimentés et qualifiés basés à Dubaï, aux EAU. Notre principal objectif est de fournir des services complets de TVA, de gestion fiscale et de comptabilité aux entreprises opérant à Dubaï et à travers les Émirats.",

        other: "Autre",
        inUAE: "aux EAU",
        "Ready to boost your business in Dubai? We're here to help":
          "Prêt à dynamiser votre entreprise à Dubaï? Nous sommes là pour vous aider",

        // ===== DETAILS DES SERVICES =====
        // businessSetupServices
        UAEMainlandBusinessSetup:
          "Configuration d'entreprise dans les ÉAU continentaux",
        UAEFreezoneBusinessSetup:
          "Configuration d'entreprise dans la zone franche des EAU",
        "Unlocking Business Potential with a UAE Mainland License":
          "Débloquer le potentiel commercial avec une licence dans les ÉAU continentaux",
        mainlandLicenseText:
          "Une licence dans les Émirats arabes unis permet aux entreprises d'opérer de manière polyvalente dans la juridiction locale, couvrant un large éventail d'activités. Elle impose une présence physique sur le continent, favorisant ainsi l'engagement direct sur le marché. Cette licence facilite le commerce local et international, offre un accès aux projets gouvernementaux et autorise les transactions dans n'importe quelle devise. Dans l'ensemble, elle assure la conformité avec les réglementations locales, favorisant une image professionnelle de l'entreprise sur le marché des EAU.",

        // licenseServices
        accesstoLocalandGlobalmarkets:
          "Accès au marché local et mondial",
        diversificationofRevenueStreams:
          "Diversification des sources de revenus",
        accessToGovernmentIncentives: "Accès aux incitations gouvernementales",
        accessToSkilledLabor: "Accès à une main-d'œuvre qualifiée",
        // proServices
        attestationServices: "Services d'attestation",
        visaAndMedicalTyping: "Saisie de visa et médicale",
        emiratesIDTyping: "Saisie de l'identifiant émirien",

        // visaServices
        schengenVisa: "Visa Schengen",
        goldenVisa: "Visa doré",

        // ===== PAGES DES SERVICES =====
        // businessSetupServices
        whyToChooseUAE: "Pourquoi choisir les EAU",
        "Top Reasons to Choose the UAE for Your Business":
          "Principales raisons de choisir les EAU pour votre entreprise",
        // "Discover how the UAE's strategic location, economic stability, and business-friendly environment make it a prime choice for global entrepreneurs.":
        whyToChooseDesc:
          "Découvrez comment la situation stratégique, la stabilité économique et l'environnement favorable aux entreprises des EAU en font un choix de premier ordre pour les entrepreneurs mondiaux.",

        strategicLocation: "Emplacement stratégique",
        strategicLocationDesc:
          "Positionné au carrefour de l'Asie, de l'Europe et de l'Afrique, les Émirats Arabes Unis offrent un accès facile aux marchés internationaux.",
        stableEconomy: "Économie stable",
        stableEconomyDesc:
          "Reconnu pour sa résilience économique et sa croissance constante, offrant un environnement sûr pour l'investissement.",

        taxBenefits: "Avantages fiscaux",
        taxBenefitsDesc:
          "Profitez de responsabilités fiscales minimales sans impôts sur le revenu personnel et diverses exemptions pour les entreprises.",

        globalConnectivity: "Connectivité mondiale",
        globalConnectivityDesc:
          "Une connectivité aérienne et maritime exceptionnelle facilite les opérations commerciales mondiales de manière plus fluide et efficace.",

        qualityOfLife: "Qualité de vie",
        qualityOfLifeDesc:
          "Des normes de vie élevées avec d'excellents services de santé, d'éducation et de loisirs.",

        culturalDiversity: "Diversité culturelle",
        culturalDiversityDesc:
          "Une main-d'œuvre multiculturelle enrichit les perspectives commerciales et améliore l'avantage concurrentiel.",

        legalFramework: "Cadre juridique",
        legalFrameworkDesc:
          "Des structures juridiques solides protègent les investissements et simplifient les opérations commerciales.",

        fullOwnership: "Pleine propriété",
        fullOwnershipDesc:
          "Les investisseurs étrangers peuvent posséder entièrement leurs entreprises sans avoir besoin d'un partenaire local.",

        // from pdf
        whyUae: "Pourquoi les EAU",
        businessSetupServicesPara1:
          "La création d'une entreprise aux EAU présente des avantages convaincants, notamment une position stratégique, une économie stable, des avantages fiscaux, une connectivité mondiale, des politiques favorables aux entreprises, une diversité culturelle, un cadre juridique, une pleine propriété, une qualité de vie, un hub d'innovation et bien plus encore!",
        businessSetupServicesPara2:
          "Ces facteurs positionnent collectivement les EAU comme une destination attrayante pour les entrepreneurs et les entreprises recherchant la croissance, la connectivité mondiale et un environnement favorable aux entreprises.",
        businessSetupServicesPara3:
          "Dans l'économie mondiale dynamique d'aujourd'hui, les Émirats Arabes Unis (EAU) se tiennent à l'avant-garde d'une croissance économique sans précédent, facilitant d'importants investissements et favorisant des relations commerciales solides avec des partenaires estimés. Renommés pour leur prospérité économique, les EAU offrent une opportunité sans précédent aux entrepreneurs visionnaires de se lancer dans des projets commerciaux transformateurs.",
        businessSetupServicesPara4:
          "Naviguer dans le processus de création d'entreprise aux EAU peut sembler intimidant pour ceux qui ne sont pas familiers avec leur paysage réglementaire. Cependant, avant que les appréhensions n'obscurcissent vos ambitions, envisagez de choisir les Services Commerciaux SKV",
        businessSetupServicesPara5:
          "Chez SKV, nous sommes spécialisés dans le guidage des entrepreneurs aspirants à travers chaque aspect de l'établissement d'une présence commerciale aux EAU. Avec notre engagement inébranlable envers l'excellence et nos services de soutien complets, nous démystifions les complexités de la mise en place d'une entreprise, vous permettant de réaliser vos aspirations entrepreneuriales en toute confiance.",
        businessSetupServicesPara6:
          "Saisissez la perspective d'un avenir commercial prospère aux EAU avec SKV. Laissez-nous être votre partenaire de confiance dans le parcours vers le succès entrepreneurial dans l'une des destinations commerciales les plus dynamiques au monde. ",

        mainland: "Continental",
        mainlandText:
          "Une licence continentale aux Émirats Arabes Unis permet aux entreprises d'opérer de manière polyvalente dans la juridiction locale, couvrant un large éventail d'activités. Elle exige une présence physique sur le continent, favorisant ainsi l'engagement direct sur le marché. Cette licence facilite le commerce local et international, offre un accès aux projets gouvernementaux et autorise les transactions dans n'importe quelle devise. Dans l'ensemble, elle assure la conformité avec les réglementations locales, favorisant une image professionnelle de l'entreprise sur le marché des EAU.",
        comprehensiveServicesForMBS:
          "Services complets pour la création d'entreprise sur le continent",
        // mainland visa reasons
        investorVisa: "Visa d'investisseur",
        investorVisaText:
          "Permet à vous et à vos employés de travailler légalement aux Émirats arabes unis. Ce visa est indispensable pour les opérationnels et le management.",
        workVisa: "Visa de travail",
        workVisaText:
          "Reconnu pour sa résilience économique et sa croissance constante, offrant un environnement sûr pour l'investissement.",
        familyResidenceVisa: "Visa de résidence familiale",
        familyResidenceVisaText:
          "Permet aux membres de la famille de l'investisseur de résider aux Émirats arabes unis. Ce visa est lié au visa d’investisseur et est généralement valable pour la même durée.",
        businessLicense: "Licences et documentation",
        businessLicenseText:
          "Indispensable pour opérer en toute légalité, l'autorisation précise le type d'activité commerciale et est requise pour toute opération commerciale.",
        labourCards: "Cartes de travail",
        labourCardsText:
          "Délivrée par le Ministère des Ressources Humaines et de l'Émiratisation, cette carte est nécessaire pour l'embauche des salariés et la gestion des contrats de travail.",
        emiratesId: "Identifiant Emirates",
        emiratesIdText:
          "Une carte d'identité obligatoire pour tous les résidents, y compris les propriétaires d'entreprises et les employés, utilisée à diverses fins administratives et juridiques.",
        governmentApprovals: "Approbations gouvernementales",
        governmentApprovalsText:
          "Requis pour le processus d'installation, y compris les approbations des autorités compétentes pour les activités commerciales et les locaux.",
        renewalOfBusinessLicense: "Renouvellement de la licence commerciale",
        renewalOfBusinessLicenseText:
          "Garantit que votre entreprise reste conforme aux réglementations locales en mettant à jour votre licence avant son expiration.",

        stepsAndRequirements: "Étapes et exigences",
        chooseYourBusinessActivity: "Choisissez votre activité commerciale",
        chooseYourBusinessActivityText:
          "Déterminez les activités spécifiques dans lesquelles votre entreprise s'engageren. Cela est crucial car cela impactera le type de licence dont vous aurez besoin et les approbations requises. Cette image mais dans un format différent et couleur",
        LLCDubai: "LLC Dubaï",
        selectALegalStructure: "Sélectionnez une structure juridique",
        selectALegalStructureText:
          "La structure juridique la plus courante pour les entreprises du continent aux Émirats Arabes Unis est une Société à Responsabilité Limitée (LLC). Mais il existe diverses autres options:",
        differentFormatAndColor: "Format et couleur différents",
        reserveATradeName: "Réservez un nom commercial",
        reserveATradeNameText:
          "Choisissez un nom commercial unique pour votre entreprise et soumettez-le au Département du Développement Économique (DED) pour approbation. Le nom doit être pertinent pour votre activité commerciale et conforme aux conventions de dénomination des EAU.",
        soleProprietorship: "Entreprise Individuelle",
        civilCompany: "Société Civile",
        limitedLiabilityCompany: "Société à Responsabilité Limitée",
        branchesAndRepresentationOffices:
          "Filiales et bureaux de représentation",

        rentOfficeSpace: "Louez un espace de bureau",
        rentOfficeSpaceText:
          "Louez un espace de bureau physique dans un emplacement approuvé par les autorités. Cet espace de bureau doit répondre à certaines exigences et est obligatoire pour les entreprises du continent aux Émirats Arabes Unis.",
        obtainVisas: "Obtenez des visas",
        obtainVisasText:
          "Demandez des visas de résidence et des permis de travail pour vous-même, vos partenaires et tous les employés éventuels. Ce processus implique des tests médicaux, des contrôles de sécurité et d'autres formalités. Ouvrez un compte bancaire professionnel: ouvrez un compte bancaire professionnel avec une banque des EAU en utilisant les documents de la société et le visa de résidence. Les banques peuvent avoir des exigences spécifiques et des procédures de documentation.",
        ourServcesAndFaq: "Nos Services et FAQ?",
        openACorporateBankAccount: "Ouvrir un compte bancaire professionnel",
        openACorporateBankAccountText:
          "Ouvrez un compte bancaire professionnel avec une banque des EAU en utilisant les documents de la société et le visa de résidence. Les banques peuvent avoir des exigences spécifiques et des procédures de documentation.",
        manageYourTeamWithReports: "Gérez votre équipe avec des rapports",
        manageYourTeamWithReportsText:
          "Mesurez ce qui compte avec les rapports faciles à utiliser d'Untitled. Vous pouvez filtrer, exporter et approfondir les données en quelques clics.",

        freezone: "Zone franche",
        freezoneText:
          "Une licence de zone franche aux Émirats Arabes Unis accorde aux entreprises l'opportunité d'opérer dans des zones désignées, offrant des avantages distincts tels que des exonérations fiscales, une pleine propriété et des processus simplifiés. Cette licence facilite un processus de création d'entreprise rationalisé et est particulièrement attrayante pour ceux qui recherchent un environnement favorable aux entreprises aux EAU.",
        // free zone business setup reasons
        noNeedForPhysicalPresence: "Pas besoin de présence physique",
        noNeedForPhysicalPresenceText:
          "Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis.",
        limitedDirectinteractionWithGov:
          "Interaction directe limitée avec le gouvernement.",
        limitedDirectinteractionWithGovText:
          "Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis.",
        exemptionFromVAT: "Exonération de la taxe sur la valeur ajoutée (TVA)",
        exemptionFromVATText:
          "Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis.",
        corporateTaxExemption: "Exonération de l'impôt sur les sociétés",
        corporateTaxExemptionText:
          "Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis.",
        versatileBusinessType: "Type d'entreprise polyvalent",
        versatileBusinessTypeText:
          "Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis.",
        stayInformedAboutChangesAndRegulations:
          "Restez informé par nous de tout changement dans les réglementations ou les exigences qui pourraient affecter vos opérations commerciales.",
        "Ifza, Rak, Shams, Ajman, Dmcc, Dubai Knowledge Village, Academic City, Maydaan":
          "Ifza, Rak, Shams, Ajman, Dmcc, Dubai Knowledge Village, Academic City, Maydaan",
        "Need Form Here with all services option: Book now for expert guidance!":
          "Besoin d'un formulaire ici avec toutes les options de services: Réservez maintenant pour un accompagnement d'expert!",
        "Unlocking UAE Freezone Business Setup":
          "Déverrouillage de la création d'entreprise en zone franche aux Émirats Arabes Unis",
        yourPathwayToBusinessSuccess: "Votre voie vers le succès commercial",
        exploreStepsAndRequirementsText:
          "Explorez les étapes cruciales et les exigences nécessaires pour établir et lancer avec succès votre entreprise.",
        comprehensiveServicesForFBS:
          "Services complets pour la création d'entreprise en zone franche",
        comprehensiveServicesTextForMBS:
          "La configuration commerciale sur le continent des Émirats arabes unis offre un accès complet au marché, une flexibilité et aucune restriction sur le commerce au sein des Émirats arabes unis ou à l'échelle internationale.",
        comprehensiveServicesTextForFBS:
          "La configuration commerciale de la zone franche des Émirats arabes unis offre une propriété étrangère à 100 %, des exonérations fiscales et des processus simplifiés dans les zones désignées, idéales pour le commerce international.",
        documents: "Documents",
        processDocuments: "Traiter les documents",
        letsStartWithDocumentation:
          "Commençons par le processus de documentation",
        procedureAndRequirement: "Procédure et exigences",
        choosingTheRightFreeZone: "Choisir la bonne zone franche",
        choosingTheRightFreeZoneText:
          "Recherchez les différentes zones franches aux Émirats Arabes Unis pour trouver celle qui correspond le mieux à vos besoins commerciaux. Tenez compte de facteurs tels que l'emplacement, la spécialisation industrielle, les installations et les réglementations.",
        selectingBusinessActivity: "Sélectionner l'activité commerciale",
        selectingBusinessActivityText:
          "Une fois que vous avez sélectionné la zone franche, déterminez les activités spécifiques dans lesquelles votre entreprise s'engagera. Les zones franches autorisent généralement des activités spécifiques dans leur juridiction, assurez-vous donc que vos activités choisies sont conformes aux réglementations de la zone franche.",
        selectingCompanyName: "Sélectionner le nom de l'entreprise",
        selectingCompanyNameText:
          "Sélectionnez un nom unique et pertinent pour votre entreprise. Assurez-vous qu'il est conforme aux conventions de dénomination de la zone franche choisie.",
        submittingApplicationAndDocs: "Soumettre la demande et les documents",
        submittingApplicationAndDocsText:
          "Une fois que vous avez terminé le processus ci-dessus, vous devrez remplir le formulaire de demande et soumettre les documents qui comprennent généralement:",
        submittingApplicationAndDocsPoint1:
          "Copies des passeports des actionnaires et des directeurs.",
        submittingApplicationAndDocsPoint2:
          "Preuve de l'adresse des actionnaires et des directeurs.",
        submittingApplicationAndDocsPoint3:
          "Plan d'affaires décrivant les activités de l'entreprise et les projections financières.",
        submittingApplicationAndDocsPoint4:
          "Certificat de non-objection (NOC) si applicable.",
        submittingApplicationAndDocsPoint5:
          "Autres documents spécifiques requis par l'autorité de la zone franche.",
        legalDocumentationAndLicenseApplication:
          "Documentation légale et demande de licence",
        legalDocumentationAndLicenseApplicationText1:
          "Une fois votre demande approuvée, vous recevrez les documents légaux de l'autorité de la zone franche, notamment le Mémorandum d'Association (MOA) et les Articles d'Association (AOA). Revoyez et signez ces documents.",
        legalDocumentationAndLicenseApplicationText2:
          "Demandez la licence commerciale correspondant à vos activités choisies.",
        legalDocumentationAndLicenseApplicationText3:
          "Les zones franches proposent généralement différents types de licences, telles que des licences commerciales, industrielles, de services ou professionnelles.",
        bankAccountOpening: "Ouverture d'un compte bancaire",
        bankAccountOpeningText:
          "Ouvrez un compte bancaire professionnel avec une banque de votre choix. Fournissez à la banque les documents légaux nécessaires obtenus auprès de l'autorité de la zone franche, y compris la licence et le contrat de bail.",
        visaProcessing: "Traitement des visas",
        visaProcessingText1:
          "Demandez des visas de résidence et des permis de travail pour les actionnaires, les directeurs et les employés si nécessaire. Chaque zone franche a ses propres réglementations en matière de visa et ses quotas",
        visaProcessingText2:
          "Soumettez les documents nécessaires, y compris les copies des passeports, les formulaires de demande de visa, les certificats médicaux et les formulaires de demande de carte d'identité des Emirats. Conformité et Renouvellements - Assurez-vous de respecter en permanence les réglementations de la zone franche choisie, y compris le renouvellement en temps voulu des licences, des visas et des contrats de location.",
        complianceAndRenewals: "Conformité et renouvellements",
        complianceAndRenewals1:
          "Assurez-vous de respecter en permanence les réglementations de la zone franche choisie, y compris le renouvellement en temps voulu des licences, des visas et des contrats de location.",
        complianceAndRenewals2:
          "Restez informé par nous de tout changement dans les réglementations ou les exigences qui pourraient affecter vos opérations commerciales.",
        // licenseServices
        "Your Partner for Seamless Business Licensing in Dubai":
          "Votre partenaire pour l'obtention facile de licences commerciales à Dubai",
        commercialLicense: "Licence Commerciale",
        industrialLicense: "Licence Industrielle",
        professionalLicense: "Licence Professionnelle",
        licenseServicesPara1:
          "À Dubaï, aux Émirats Arabes Unis, l'obtention d'une licence commerciale est une exigence indispensable pour une opération légale. Le processus implique d'obtenir des approbations des départements gouvernementaux et économiques, ainsi que la validation des documents de formation de l'entreprise par divers organismes des EAU.",
        licenseServicesPara2:
          "Face à cette complexité, SKV émerge comme le partenaire essentiel pour établir et nourrir vos entreprises à Dubai. Forts d'une expérience étendue et d'un engagement indéfectible à favoriser la croissance, nous offrons une gamme complète de services adaptés à vos besoins spécifiques. Notre expertise va au-delà de la simple facilitation, car nous vous conseillons sur le choix de la licence la plus adaptée à votre projet d'entreprise. De l'acquisition au renouvellement, nous supervisons l'ensemble du processus de licence, garantissant la conformité et allégeant votre charge.",
        licenseServicesPara3Title:
          "Donnez de l'impulsion à vos aspirations commerciales avec SKV, votre partenaire inébranlable pour propulser votre parcours entrepreneurial vers le succès dans le paysage dynamique de Dubai. Acquérir une licence sur le continent à Dubai offre plusieurs avantages distincts pour les entreprises:",
        "Access to Local Market and Global markets":
          "Accès aux marchés locaux et mondiaux",
        "Diversification of Revenue Streams":
          "Diversification des sources de revenus",
        "Access to Government Incentives":
          "Accès aux incitations gouvernementales",
        "Access to Skilled Labor": "Accès à une main-d'œuvre qualifiée",
        "Requirements for obtaining a trade license":
          "Exigences pour obtenir une licence commerciale",
        "The process of obtaining a Business Trade License in Dubai involves several detailed steps.":
          "Le processus d'obtention d'une licence commerciale à Dubaï implique plusieurs étapes détaillées.",
        initialConsultation: "Consultation Initiale",
        initialConsultaionText:
          "SKV Business Services initie le processus par une consultation approfondie pour comprendre vos besoins commerciaux, nous permettant de vous fournir des conseils personnalisés sur la licence la plus adaptée à vos opérations.",
        documentationPreparation: "Préparation de la Documentation",
        documentPreperationText:
          "Nous vous guidons dans la préparation méticuleuse de tous les documents de formation de l'entreprise nécessaires. Cela inclut les documents légaux, les états financiers et d'autres documents essentiels requis pour les approbations des départements gouvernementaux et économiques.",
        govAndEDA: "Approbations Gouvernementales et du Département Économique",
        govAndEDAText:
          "SKV facilite la soumission et le traitement de vos documents auprès des autorités gouvernementales et du département économique. Notre expertise garantit un processus d'approbation fluide, minimisant les retards et les incertitudes.",
        licenseAcquisition: "Acquisition de Licence",
        licenseAcquisitionText:
          "Une fois les approbations obtenues, nous vous assistons dans l'acquisition de la licence commerciale. Cela implique une coordination avec les autorités compétentes pour finaliser le cadre juridique de vos activités commerciales.",
        ongoingSupport: "Support Continu",
        ongoingSupportText:
          "SKV Business Services ne s'arrête pas à l'acquisition de la licence; nous offrons un soutien et des conseils continus pour la croissance de votre entreprise à Dubai.",
        benefitOfChoosingSKVLicenseService:
          "En choisissant SKV, vous bénéficiez d'une approche complète et détaillée de la création d'entreprise, allégeant les complexités impliquées et vous permettant de vous concentrer sur le succès et l'expansion de votre entreprise à Dubai.",
        // translationServices
        certifiedTranslators: "Traducteurs Certifiés",
        certifiedTranslatorsText:
          "Notre équipe est composée de traducteurs professionnels ayant une connaissance spécialisée du vocabulaire juridique, garantissant des traductions précises et contextuellement appropriées.",
        comprehensiveOffering: "Offre Complète",
        comprehensiveOfferingText:
          "SKV répond à divers secteurs d'activité en fournissant des services de traduction spécialisés pour une large gamme de documents juridiques. Cela inclut les contrats, les accords, les documents judiciaires et les écrits académiques.",
        accuracyAndPrecision: "Précision et Exactitude",
        accuracyAndPrecisionText:
          "Nous accordons la priorité à la précision pour éviter les mauvaises interprétations pouvant avoir des implications juridiques. Une attention minutieuse est portée pour maintenir l'intégrité du contenu juridique original lors de la traduction.",
        confidentialityAndSecurity: "Confidentialité et Sécurité",
        confidentialityAndSecurityText:
          "SKV accorde une grande importance à la confidentialité des documents juridiques. Des mesures de sécurité strictes sont en place pour protéger les informations sensibles lors du processus de traduction.",
        timelyDelivery: "Livraison en Temps Opportun",
        timelyDeliveryText:
          "Reconnaissant l'importance des délais dans les affaires juridiques, SKV s'engage à livrer en temps opportun sans compromettre la qualité des traductions. En choisissant SKV, les clients bénéficient d'une approche méticuleuse et axée sur les détails de la traduction juridique, garantissant des documents précis et légalement traduits.",
        benefitOfChoosingSKVTranslationService:
          "En choisissant SKV, les clients bénéficient d'une approche méticuleuse et axée sur les détails de la traduction juridique, garantissant des documents précis et légalement traduits.",
        "Need Form Here with all service option: Book now for expert guidance!":
          "Besoin d'un formulaire ici avec toutes les options de service: Réservez dès maintenant pour un accompagnement d'expert !",

        // proServices
        proServicesPara1:
          "Établi avec un engagement indéfectible à simplifier les services PRO aux Émirats Arabes Unis, SKV incarne l'excellence en facilitant les créations d'entreprise sans faille. Dans une nation renommée pour son environnement favorable aux investisseurs, le processus de lancement d'une entreprise nécessite une attention méticuleuse à la documentation et aux approbations. Reconnaissant la complexité inhérente à cette entreprise, SKV offre une solution complète en étendant notre expertise en tant que votre agent PRO dédié.",
        proServicesPara2:
          "Notre gamme complète de services couvre chaque aspect de la création d'entreprise, englobant la formation d'entreprise, l'obtention de licences, la carte d'identité des Émirats, la procuration, la certification et une gamme de tâches liées aux relations publiques. Comprendre que chaque entreprise possède ses propres exigences distinctes, nous abordons nos services PRO aux EAU avec une mentalité proactive, en veillant à ce que chaque aspect soit pleinement conforme aux réglementations légales en vigueur.",
        whatAreProServicesInDubai: "Quels sont les Services PRO à Dubai ?",
        proServicesAns1:
          "Un Officier des Relations Publiques (PRO) assume un rôle critique dans les activités administratives pour l'initiation réussie et le fonctionnement durable des entreprises.",
        proServicesAns2:
          "De l'initiation à l'exploitation, le PRO sert de guide, guidant les clients à travers les procédures imposées par les organismes de réglementation. Leur soutien complet s'étend à la facilitation de tâches essentielles telles que le renouvellement de licences, les services de visa, les accords de travail, et bien plus encore. S'appuyant sur leur profonde compréhension des réglementations et protocoles des EAU, le PRO assure que chaque étape du processus est exécutée conformément à la réglementation légale.",
        proServicesAns3:
          "Au-delà de la phase de configuration initiale, le PRO reste un partenaire dévoué, s'engageant à assurer la continuité sans faille des activités commerciales.",
        // some of pro services
        labourCardRenewal: "Renouvellement de la Carte de Travail",
        licenseServices: "Services de Licence",
        translationServices: "Services de Traduction",
        attestationServices: "Services d'Attestation",
        visaAndMedicalTyping: "Services de Visa et de Dactylographie Médicale",
        SIRAServices: "Services SIRA",
        emiratesIDTyping:
          "Services de Dactylographie de Carte d'Identité des Émirats",
        powerOfAttorney: "Procuration",
        localSponsorship: "Parrainage Local",
        AmerAndTasheelServices: "Services Amer et Tasheel",
        AML: "LBC",
        // visaservices
        chooseYourVisa: "Choisissez votre visa",
        "30 Days Single entry, 60 days Single entry, 90 Days Single entry":
          "30 jours Entrée unique, 60 jours Entrée unique, 90 jours Entrée unique",
        "30 Days Multi entry, 60 Days Multi entry, 5 years Multi - Visa, Extension Visa":
          "30 jours Entrée multiple, 60 jours Entrée multiple, Visa multiple de 5 ans, Visa d'extension",
        "This Format except for the prices and visas which are written above":
          "Ce format à l'exception des prix et des visas qui sont écrits ci-dessus",

        schengenVisa: "Visa Schengen",
        schengenVisaPara1:
          "Le visa Schengen est un type de visa de court séjour qui permet aux voyageurs de visiter l'espace Schengen, qui comprend 26 pays européens.",
        eligibility: "Éligibilité",
        schengenVisaEligibilityText1:
          "Les ressortissants de certains pays doivent obtenir un visa Schengen avant d'entrer dans l'espace Schengen. Les critères d'éligibilité peuvent varier en fonction de la nationalité du demandeur, du motif du voyage et d'autres facteurs.",
        schengenVisaEligibilityText2:
          "Les demandeurs doivent démontrer l'intention de quitter l'espace Schengen avant l'expiration du visa et fournir des preuves de ressources suffisantes pour couvrir leur séjour.",
        typesOfSchengenVisa: "Types de visas Schengen",
        uniformSchengenVisa: "Visas Schengen Uniformes (VSU)",
        uniformSchengenVisaText:
          "Ce sont des visas de court séjour permettant des séjours pouvant aller jusqu'à 90 jours sur une période de 180 jours.",
        "USVs are further categorized into": "Les VSU sont ensuite classés en",
        singleEntryVisa: "Visa à entrée unique",
        singleEntryVisaText:
          "Permet une entrée dans l'espace Schengen une seule fois.",
        doubleEntryVisa: "Visa à double entrée",
        doubleEntryVisaText:
          "Permet deux entrées dans l'espace Schengen pendant la période de validité du visa.",
        multipleEntryVisa: "Visa à entrées multiples",
        multipleEntryVisaText:
          "Permet plusieurs entrées dans l'espace Schengen pendant la période de validité du visa.",
        applicationProcess: "Processus de demande",
        applicationProcessText1:
          "Le processus de demande implique généralement de remplir un formulaire de demande, de fournir des documents à l'appui (comme passeport, itinéraire de voyage, réservations d'hébergement, preuve de moyens financiers, assurance voyage et photos de visa) et de participer à un entretien de visa si nécessaire.",
        applicationProcessText2:
          "Les demandeurs peuvent également devoir payer des frais de demande de visa non remboursables.",
        processingTime: "Délai de traitement",
        processingTimeText1:
          "Le délai de traitement des demandes de visa Schengen varie en fonction du consulat ou de l'ambassade et du volume des demandes reçues. Il peut aller de quelques jours à plusieurs semaines.",
        processingTimeText2:
          "Il est conseillé aux demandeurs de demander le visa bien à l'avance par rapport à leurs dates de voyage prévues pour permettre un temps de traitement suffisant.",
        schengenAreaCountries: "Pays de l'espace Schengen",
        schengenAreaCountriesText:
          "L'espace Schengen comprend 22 États membres de l'UE et quatre États non membres de l'UE : Autriche, Belgique, République tchèque, Danemark, Estonie, Finlande, France, Allemagne, Grèce, Hongrie, Islande, Italie, Lettonie, Liechtenstein, Lituanie, Luxembourg, Malte, Pays-Bas, Norvège, Pologne, Portugal, Slovaquie, Slovénie, Espagne, Suède et Suisse.",
        goldenVisa: "Visa d'Or",
        goldenVisaPara1:
          "Le programme de Visa d'Or de Dubai est une initiative prestigieuse offerte par le gouvernement des Émirats Arabes Unis (EAU), spécialement conçue pour accorder une résidence à long terme aux expatriés. Dans le cadre de ce programme, les personnes éligibles peuvent obtenir un visa de résidence qui leur accorde le droit de résider, de travailler, d'étudier ou de faire des affaires à Dubaï pendant une période prolongée, jusqu'à 10 ans.",
        keyFeaturesAndBenefits: "Caractéristiques Clés et Avantages",
        longTermResidency: "Résidence à Long Terme",
        longTermResidencyText:
          "Les détenteurs du Visa d'Or de Dubai ont le privilège de résider dans l'Émirat pendant une durée pouvant aller jusqu'à 10 ans, offrant stabilité et sécurité à eux-mêmes et à leur famille.",
        freedomToWorkStudy:
          "Liberté de travailler, d'étudier et de faire des affaires",
        freedomToWorkStudyText:
          "Les titulaires d'un Golden Visa ont la possibilité de rechercher des opportunités d'emploi, de poursuivre des études supérieures ou de créer et gérer leur entreprise à Dubaï sans avoir besoin d'un sponsor émirati local.",
        multiLevelPermit: "Permis à Niveaux Multiples",
        multiLevelPermitText:
          "Les détenteurs du Visa d'Or bénéficient d'un permis à entrées multiples aux EAU, facilitant les voyages entrants et sortants du pays pour des raisons professionnelles, de loisirs ou personnelles.",
        applicationProcess: "Processus de Demande",
        eligibilityCriteria: "Critères d'Éligibilité",
        eligibilityCriteriaText:
          "Le programme Golden Visa à Dubaï est ouvert à certaines catégories de personnes, notamment des investisseurs, des entrepreneurs, des cadres, des professionnels et des étudiants exceptionnels, entre autres. Les critères d'éligibilité peuvent varier en fonction des qualifications, des réalisations et du potentiel de contribution du candidat. Voici quelques catégories :",
        documentationRequirements: "Exigences en Matière de Documentation",
        documentationRequirementsText:
          "Les demandeurs doivent soumettre un ensemble complet de documents, notamment des copies de passeport, des preuves de stabilité financière, des enregistrements d'investissements, des qualifications éducatives.",
        whyChooseSKVForGoldenVisa:
          "Pourquoi Choisir SKV pour les Services de Visa d'Or?",
        whyChooseSKVForGoldenVisaText:
          "SKV est un prestataire de confiance de services de visa d'or complets, offrant une orientation et un soutien d'experts aux personnes souhaitant obtenir une résidence à long terme à Dubaï. Notre équipe de professionnels expérimentés assiste les clients à chaque étape du processus de demande, de la consultation initiale à la préparation et à la soumission des documents, garantissant une expérience fluide et sans tracas.",
        benefitOfChoosingSKVVisaService:
          "Avec SKV, les clients peuvent bénéficier de",
        persionalizedConsultaion: "Consultation Personnalisée",
        persionalizedConsultaionText:
          "Nos experts fournissent des conseils personnalisés basés sur les circonstances uniques de chaque client, garantissant une prise de décision éclairée et des résultats optimaux.",
        streamlinedProcess: "Processus Rationalisé",
        streamlinedProcessText:
          "Nous gérons tous les aspects du processus de demande de visa d'or, en tirant parti de notre expérience étendue et de notre connaissance de la réglementation sur l'immigration aux EAU pour accélérer les approbations et minimiser les retards.",
        complianceAssurance: "Assurance de Conformité",
        vatRegAndCTRPara1:
          "SKV Business Services dispose d'une équipe réputée de comptables, auditeurs et conseillers fiscaux expérimentés et qualifiés basés à Dubaï, aux Émirats arabes unis. Notre principal objectif est de fournir des services complets de TVA, de gestion fiscale et de comptabilité aux entreprises opérant à Dubaï et dans les Émirats. Nous sommes fiers de maintenir des relations solides avec les responsables du ministère, ce qui nous permet de rester au fait de l'évolution des lois commerciales et des réglementations fiscales des Émirats arabes unis. Cet avantage stratégique nous permet d'anticiper et de préparer nos clients à d'éventuels impacts sur leurs activités découlant des changements dans l'économie ou le paysage fiscal.",
        vatRegAndCTRPara2:
          "Notre équipe de consultants fiscaux dévoués à Dubaï évalue les circonstances uniques de chaque client pour développer des stratégies de planification fiscale sur mesure et garantir la conformité aux exigences réglementaires. Nous maintenons méticuleusement les registres financiers de votre entreprise, vous permettant de suivre précisément votre performance financière et de remplir vos obligations de déclaration.",
        "At SKV Tax Consultants and Accountants, we offer a wide range of services aimed at assisting businesses of all sizes and industries, including but not limited to:":
          "Chez SKV Tax Consultants and Accountants, nous proposons une large gamme de services visant à aider les entreprises de toutes tailles et de tous secteurs, notamment mais pas exclusivement :",
        vatRegAndCTRPoint1:
          "Enregistrement et maintien des dossiers financiers de votre entreprise",
        vatRegAndCTRPoint2:
          "Vérification des états financiers pour garantir l'exactitude et la conformité",
        vatRegAndCTRPoint3:
          "Fourniture de soutien dans les activités de banque d'investissement",
        vatRegAndCTRPoint4:
          "Tenir les clients informés des litiges fiscaux et des politiques réglementaires",
        vatRegAndCTRPoint:
          "Offrir une consultation personnalisée pour optimiser l'efficacité fiscale et améliorer la productivité financière globale",
        vatRegAndCTRPara3:
          "Notre engagement à fournir un service exceptionnel va au-delà de la simple conformité ; nous nous efforçons de donner à nos clients des informations précieuses et des opportunités pour améliorer leur efficacité financière et atteindre une croissance durable. En tirant parti de notre expertise et de nos ressources, nous créons des possibilités infinies pour nos clients de naviguer dans les complexités de la fiscalité aux Émirats arabes unis tout en maximisant leur potentiel commercial.",
        vatRegAndCTRPara4:
          "Collaborez avec SKV Business Services dès aujourd'hui et découvrez la différence que notre équipe dévouée peut apporter dans l'optimisation de vos processus fiscaux et comptables, en faisant avancer votre entreprise avec confiance et succès.",
        chooseYourVisa: "Choisissez votre visa",
        "30 Days Single entry, 60 days Single entry, 90 Days Single entry":
          "30 jours Entrée unique, 60 jours Entrée unique, 90 jours Entrée unique",
        "30 Days Multi entry, 60 Days Multi entry, 5 years Multi - Visa, Extension Visa":
          "30 jours Entrées multiples, 60 jours Entrées multiples, Visa multi-années 5 ans, Visa de prolongation",
        "This Format except for the prices and visas which are written above":
          "Ce format sauf pour les prix et les visas qui sont écrits ci-dessus",
        schengenVisa: "Visa Schengen",
        schengenVisaPara1:
          "Le visa Schengen est un type de visa de court séjour qui permet aux voyageurs de visiter l'espace Schengen, qui comprend 26 pays européens.",
        eligibility: "Éligibilité",
        schengenVisaEligibilityText1:
          "Les ressortissants de certains pays doivent obtenir un visa Schengen avant d'entrer dans l'espace Schengen. Les critères d'éligibilité peuvent varier en fonction de la nationalité du demandeur, du motif du voyage et d'autres facteurs.",
        schengenVisaEligibilityText2:
          "Les demandeurs doivent démontrer l'intention de quitter l'espace Schengen avant l'expiration du visa et fournir des preuves de ressources suffisantes pour couvrir leur séjour.",
        typesOfSchengenVisa: "Types de visas Schengen",
        uniformSchengenVisa: "Visas uniformes Schengen (VUS)",
        uniformSchengenVisaText:
          "Ce sont des visas de court séjour permettant des séjours allant jusqu'à 90 jours sur une période de 180 jours.",
        "USVs are further categorized into":
          "Les VUS sont en outre catégorisés en",
        singleEntryVisa: "Visa à entrée unique",
        singleEntryVisaText: "Permet une seule entrée dans l'espace Schengen.",
        doubleEntryVisa: "Visa à double entrée",
        doubleEntryVisaText:
          "Autorise deux entrées dans l'espace Schengen pendant la période de validité du visa.",
        multipleEntryVisa: "Visa à entrées multiples",
        multipleEntryVisaText:
          "Permet plusieurs entrées dans l'espace Schengen pendant la période de validité du visa.",
        applicationProcess: "Processus de demande",
        applicationProcessText1:
          "Le processus de demande implique généralement de remplir un formulaire de demande, de fournir des documents justificatifs (comme le passeport, l'itinéraire de voyage, les réservations d'hébergement, la preuve de moyens financiers, une assurance voyage et des photos de visa), et de participer à un entretien si nécessaire.",
        applicationProcessText2:
          "Les demandeurs peuvent également devoir payer des frais de demande de visa non remboursables.",
        processingTime: "Délai de traitement",
        processingTimeText1:
          "Le délai de traitement des demandes de visa Schengen varie en fonction du consulat ou de l'ambassade et du volume des demandes reçues. Il peut varier de quelques jours à plusieurs semaines.",
        processingTimeText2:
          "Il est conseillé aux demandeurs de demander le visa bien à l'avance par rapport à leurs dates de voyage prévues pour permettre un temps de traitement suffisant.",
        schengenAreaCountries: "Pays de l'espace Schengen",
        schengenAreaCountriesText:
          "L'espace Schengen comprend 22 États membres de l'UE et quatre États non membres de l'UE : Autriche, Belgique, République tchèque, Danemark, Estonie, Finlande, France, Allemagne, Grèce, Hongrie, Islande, Italie, Lettonie, Liechtenstein, Lituanie, Luxembourg, Malte, Pays-Bas, Norvège, Pologne, Portugal, Slovaquie, Slovénie, Espagne, Suède et Suisse.",
        goldenVisa: "Visa doré",
        goldenVisaPara1:
          "Le programme de visa doré de Dubaï est une initiative prestigieuse offerte par le gouvernement des Émirats arabes unis (EAU), spécialement conçue pour accorder une résidence à long terme aux expatriés. Dans le cadre de ce programme, les personnes éligibles peuvent obtenir un visa de résidence qui leur accorde le droit de résider, de travailler, d'étudier ou de faire des affaires à Dubaï pendant une période prolongée, jusqu'à 10 ans.",
        keyFeaturesAndBenefits: "Caractéristiques clés et avantages",
        documentationRequirements: "Exigences en matière de documentation",
        persionalizedConsultaion: "Consultation personnalisée",
        persionalizedConsultaionText:
          "Nos experts fournissent des conseils personnalisés en fonction des circonstances uniques de chaque client, garantissant une prise de décision éclairée et des résultats optimaux.",
        streamlinedProcess: "Processus rationalisé",
        streamlinedProcessText:
          "Nous gérons tous les aspects du processus de demande de visa doré, en tirant parti de notre expérience et de notre connaissance approfondie des réglementations en matière d'immigration aux EAU pour accélérer les approbations et réduire les délais.",
        complianceAssurance: "Assurance de conformité",
        complianceAssuranceText:
          "SKV assure le respect strict des exigences réglementaires et des normes de documentation, réduisant les risques et garantissant le succès des demandes de visa doré de nos clients.",
        vatRegAndCTRPara1:
          "SKV Business Services dispose d'une équipe réputée de comptables, d'auditeurs et de conseillers fiscaux expérimentés et qualifiés basés à Dubaï, aux EAU. Notre objectif principal est de fournir des services complets de TVA, de gestion fiscale et de comptabilité aux entreprises opérant à Dubaï et dans les Émirats. Nous nous enorgueillissons de maintenir des relations solides avec les responsables du ministère, ce qui nous permet de rester à l'affût des évolutions des lois commerciales et des réglementations fiscales des EAU. Cet avantage stratégique nous permet d'anticiper et de préparer nos clients à d'éventuels impacts sur leurs entreprises découlant des changements dans l'économie ou le paysage fiscal.",
        vatRegAndCTRPara2:
          "Notre équipe de consultants fiscaux dévoués à Dubaï évalue les circonstances uniques de chaque client pour développer des stratégies de planification fiscale sur mesure et assurer le respect des exigences réglementaires. Nous maintenons méticuleusement les dossiers financiers de votre entreprise, vous permettant de suivre avec précision vos performances financières et de remplir vos obligations de déclaration.",
        "At SKV Tax Consultants and Accountants, we offer a wide range of services aimed at assisting businesses of all sizes and industries, including but not limited to:":
          "Chez SKV Tax Consultants and Accountants, nous proposons une large gamme de services visant à aider les entreprises de toutes tailles et de tous secteurs, notamment mais sans s'y limiter :",
        vatRegAndCTRPoint1:
          "Enregistrement et maintenance des dossiers financiers de votre entreprise",
        vatRegAndCTRPoint2:
          "Audit des états financiers pour garantir l'exactitude et la conformité",
        vatRegAndCTRPoint3:
          "Fournir un soutien dans les activités de banque d'investissement",
        vatRegAndCTRPoint4:
          "Tenir les clients informés des litiges fiscaux et des politiques réglementaires",
        vatRegAndCTRPoint:
          "Offrir une consultation personnalisée pour optimiser l'efficacité fiscale et améliorer la productivité financière globale",
        vatRegAndCTRPara3:
          "Notre engagement à fournir un service exceptionnel va au-delà de la simple conformité ; nous nous efforçons d'outiller nos clients avec des informations précieuses et des opportunités pour améliorer leur efficacité financière et atteindre une croissance durable. En tirant parti de notre expertise et de nos ressources, nous créons des possibilités infinies pour nos clients de naviguer dans les complexités de la fiscalité aux EAU tout en maximisant leur potentiel commercial.",
        vatRegAndCTRPara4:
          "Associez-vous à SKV Business Services dès aujourd'hui et découvrez la différence que notre équipe dévouée peut apporter dans l'optimisation de vos processus fiscaux et comptables, propulsant votre entreprise vers l'avant avec confiance et succès.",
        persionalizedConsultaion: "Consultation personnalisée",
        persionalizedConsultaionText:
          "Nos experts fournissent des conseils personnalisés en fonction des circonstances uniques de chaque client, garantissant une prise de décision éclairée et des résultats optimaux.",
        streamlinedProcess: "Processus rationalisé",
        streamlinedProcessText:
          "Nous gérons tous les aspects du processus de demande de visa doré, en tirant parti de notre expérience et de notre connaissance approfondie des réglementations en matière d'immigration aux EAU pour accélérer les approbations et réduire les délais.",
        complianceAssurance: "Assurance de conformité",
        vatRegAndCTRPara1:
          "SKV Business Services dispose d'une équipe réputée de comptables, d'auditeurs et de conseillers fiscaux expérimentés et qualifiés basés à Dubaï, aux EAU. Notre objectif principal est de fournir des services complets de TVA, de gestion fiscale et de comptabilité aux entreprises opérant à Dubaï et dans les Émirats. Nous nous enorgueillissons de maintenir des relations solides avec les responsables du ministère, ce qui nous permet de rester à l'affût des évolutions des lois commerciales et des réglementations fiscales des EAU. Cet avantage stratégique nous permet d'anticiper et de préparer nos clients à d'éventuels impacts sur leurs entreprises découlant des changements dans l'économie ou le paysage fiscal.",
        vatRegAndCTRPara2:
          "Notre équipe de consultants fiscaux dévoués à Dubaï évalue les circonstances uniques de chaque client pour développer des stratégies de planification fiscale sur mesure et assurer le respect des exigences réglementaires. Nous maintenons méticuleusement les dossiers financiers de votre entreprise, vous permettant de suivre avec précision vos performances financières et de remplir vos obligations de déclaration.",
        "At SKV Tax Consultants and Accountants, we offer a wide range of services aimed at assisting businesses of all sizes and industries, including but not limited to:":
          "Chez SKV Tax Consultants and Accountants, nous proposons une large gamme de services visant à aider les entreprises de toutes tailles et de tous secteurs, notamment mais sans s'y limiter :",
        vatRegAndCTRPoint1:
          "Enregistrement et maintenance des dossiers financiers de votre entreprise",
        vatRegAndCTRPoint2:
          "Audit des états financiers pour garantir l'exactitude et la conformité",
        vatRegAndCTRPoint3:
          "Fournir un soutien dans les activités de banque d'investissement",
        vatRegAndCTRPoint4:
          "Tenir les clients informés des litiges fiscaux et des politiques réglementaires",
        vatRegAndCTRPoint:
          "Offrir une consultation personnalisée pour optimiser l'efficacité fiscale et améliorer la productivité financière globale",
        vatRegAndCTRPara3:
          "Notre engagement à fournir un service exceptionnel va au-delà de la simple conformité ; nous nous efforçons d'outiller nos clients avec des informations précieuses et des opportunités pour améliorer leur efficacité financière et atteindre une croissance durable. En tirant parti de notre expertise et de nos ressources, nous créons des possibilités infinies pour nos clients de naviguer dans les complexités de la fiscalité aux EAU tout en maximisant leur potentiel commercial.",
        vatRegAndCTRPara4:
          "Associez-vous à SKV Business Services dès aujourd'hui et découvrez la différence que notre équipe dévouée peut apporter dans l'optimisation de vos processus fiscaux et comptables, propulsant votre entreprise vers l'avant avec confiance et succès.",
        ourBlogs: "Nos blogs",
        resourcesAndInsights: "Ressources et perspectives",
        theLatestIndustryNewsTechEtcText:
          "Les dernières nouvelles de l'industrie, des interviews, des technologies et des ressources.",
        search: "Rechercher",
        newestFirst: "Les plus récents d'abord",
        oldestFirst: "Les plus anciens d'abord",
        published: "Publié",
        latestBlogs: "Derniers blogs",
        "Interviews, tips, guides, industry best practices, and news.":
          "Interviews, conseils, guides, meilleures pratiques de l'industrie et actualités.",
        viewAllBlogs: "Voir tous les blogs",
        design: "Design",
        fromTheBlog: "Du blog",
        loadMore: "Charger plus",
        copyLink: "Copier le lien",
        blogsNotFound:
          "Blogs non trouvés ou un problème est survenu lors de la récupération des blogs !",
        noBlogsMatching: "Aucun blog ne correspond au texte de recherche !",
        "Let's level up your brand, together in Dubai":
          "Relevons ensemble votre marque à Dubaï",
        "Our friendly team would love to hear from you.":
          "Notre équipe sympathique serait ravie de vous entendre.",
        office: "Bureau",
        "Come say hello at our office HQ.":
          "Venez nous dire bonjour à notre siège social.",
        mondayToFriday: "Lundi-Vendredi",
        from: "De",
        callTimingText: "Du lundi au vendredi de 8h à 17h.",
        knowMore: "En savoir plus",
        send: "Envoyer",
        submit: "Soumettre",
        upload: "Télécharger",
        saveChanges: "Enregistrer les modifications",
        verify: "Vérifier",
        reset: "Réinitialiser",
        name: "Nom",
        enterYourName: "Entrez votre nom",
        fullName: "Nom complet",
        enterYourFullName: "Entrez votre nom complet",
        email: "Email",
        enterYourEmail: "Entrez votre email",
        phone: "Téléphone",
        enterYourPhone: "Entrez votre téléphone",
        mobile: "Portable",
        enterYourMobile: "Entrez votre portable",
        password: "Mot de passe",
        enterPassword: "Entrez le mot de passe",
        confirmPassword: "Confirmez le mot de passe",
        enteConfirmPassword: "Entrez le mot de passe de confirmation",
        currentPassword: "Mot de passe actuel",
        newPassword: "Nouveau mot de passe",
        confirmNewPassword: "Confirmer le nouveau mot de passe",
        country: "Pays",
        enterYourcountry: "Entrez votre pays",
        chooseCountry: "Choisir un pays",
        city: "Ville",
        chooseCity: "Choisir une ville",
        enterYourcity: "Entrez votre ville",
        address: "Adresse",
        addYourFullAddress: "Ajoutez votre adresse complète",
        enterYouraddress: "Entrez votre adresse",
        subject: "Sujet",
        enterSubject: "Entrez le sujet",
        message: "Message",
        call: "Call",
        mail: "Mail",
        yourRequirement: "Votre exigence",
        enterMessage: "Entrez le message",
        agreePrivacyPolicy:
          "Acceptez notre politique de confidentialité conviviale",
        iAgreeToTermsAndConditions:
          "Je suis d'accord avec les termes et conditions.",
        note: "Note",
        "Document will be shared by given address or register email id":
          "Le document sera partagé par l'adresse donnée ou l'identifiant email enregistré",
        cancel: "Annuler",
        "Please enter your current password to change your password.":
          "Veuillez saisir votre mot de passe actuel pour changer votre mot de passe.",
        "Your new password must have more than 8 characters":
          "Votre nouveau mot de passe doit comporter plus de 8 caractères",
        "Your new password must be different to previously used passwords.":
          "Votre nouveau mot de passe doit être différent des mots de passe précédemment utilisés.",
        reseting: "Réinitialisation",
        resetPassword: "Réinitialiser le mot de passe",
        leaveUsAMessage: "Laissez-nous un message",
        "You agree to our friendly privacy policy.":
          "Vous acceptez notre politique de confidentialité conviviale.",
        docsAgreeText: "J'accepte d'avoir les documents mentionnés ci-dessus.",
        resendOtp: "Renvoyer l'OTP",
        verifyEmail: "Vérifier l'email !",
        backToLogin: "Retour à la connexion",
        pleaseEnterOtpAndVerify: "Veuillez entrer l'otp et vérifier.",
        accountVerified: "Compte vérifié ?",
        forgotPassword: "Mot de passe oublié ?",
        john: "John",
        doe: "Doe",
        creating: "Création",
        sending: "Envoi",
        resetting: "Réinitialisation",
        signingUp: "Inscription",
        signingIn: "Connexion",
        signUp: "S'inscrire",
        signIn: "Se connecter",
        signOut: "Déconnexion",
        setNewPassword: "Définir un nouveau mot de passe !",
        alreadyHaveAnAccount: "Vous avez déjà un compte ?",
        letsStart: "Commençons !",
        pleaseEnterYourDetails: "Veuillez entrer vos coordonnées.",
        selectLanguage: "Choisir la langue",
        connectOnWhatsapp: "Connectez-vous sur WhatsApp",
        connectOverCall: "Connectez-vous par appel",
        dontHaveAnAccount: "Vous n'avez pas de compte ?",
        sendOtp: "Envoyer l'OTP",
        back: "dos",
        quickLinks: "Liens rapides",
        ourServices: "Nos services",
        promotions: "Promotions",
        blogs: "Blogs",
        contactUs: "Contactez-nous",
        "UAE Free zone Business Setup":
          "Configuration d'entreprise en zone franche des ÉAU",
        commercial: "Commercial",
        professional: "Professionnel",
        industrial: "Industriel",
        privacyPolicy: "Politique de confidentialité",
        termsOfService: "Conditions d'utilisation",
        cookiePolicy: "Politique en matière de cookies",
        allRightsReserved: "Tous droits réservés",
        location: "Emplacement",
        linkedIn: "LinkedIn",
        twitter: "Twitter",
        youtube: "Youtube",
        facebook: "Facebook",
        ourBranches: "Nos succursales",
        headOffice: "SIÈGE SOCIAL",
        branchOffice: "Bureau de succursale",
        dubai: "Dubaï",
        UAE: "ÉAU",
        unitedArabEmirates: "Émirats Arabes Unis",
        "Gold souq deira dubai, UAE": "Gold souq deira dubai, ÉAU",
        UK: "Royaume-Uni",
        unitedKingdom: "Royaume-Uni",
        "1st Floor 124 Cleveland Street, London, UK":
          "1er étage 124 Cleveland Street, Londres, Royaume-Uni",
        hungary: "Hongrie",
        "2073 Tök, Fő utca 1":
          "2073 Tök, Fő utca 1",
        findOurOngoingPromotions: "Trouvez nos promotions en cours",
        findOurOngoingPromotionsText:
          "Trouvez nos dernières promotions et offres spéciales ici.",
        promotions1: "Promotions 1",
        promotions1Text: "Description des promotions 1",
        goToServices: "Aller aux services",
        chatToSales: "Discuter avec les ventes",
        trackYourService: "Suivre votre service",
        trackYourProgress: "Suivez vos progrès",
        noServicesYet: "Aucun service pour l'instant !",
        plan: "Plan",
        documentStatus: "État du document",
        paymentStatus: "Statut de paiement",
        servicesStatus: "Statut des services",
        documentsReceived: "Documents reçus",
        receivedOnline: "Reçu en ligne",
        verification: "Vérification",
        verified: "Vérifié",
        verifiedOn: "Vérifié sur",
        delivery: "Livraison",
        expectedOn: "prévu sur",
        basicDetails: "Détails de base",
        paymentHistory: "Historique de paiement",
        changePassword: "Changer le mot de passe",
        settings: "Paramètres",
        login: "Connexion",
        loggingIn: "Connexion en cours",
        logout: "Déconnexion",
        account: "Compte",
        loginWithGoogle: "Se connecter avec Google",
        id: "ID",
        allOrders: "Toutes les commandes",
        goToOrder: "Aller à la commande",
        orderAlreadyCreated: "Commande déjà créée",
        buyPlan: "Acheter un plan",
        process: "Processus",
        submittedBeingReviewed: "SOUMIS - En cours de révision",
        docsBeingReviewed: "Vos documents sont en cours de révision !",
        allDocsBeingReviewed: "Tous vos documents sont examinés",
        pleaseProceedToCheckout: "Veuillez procéder au paiement",
        proceed: "Procéder",
        serviceDetails: "Détails du service",
        nameAndDetails: "Nom & Détails",
        docUpload: "Téléchargement de documents",
        requiredDocs: "Documents requis",
        documentsListRequired: "Liste des documents requis",
        billing: "Facturation",
        "Manage your billing and payment details.":
          "Gérez vos informations de facturation et de paiement.",
        pendingAmount: "Montant en attente",
        "Document will be shared only after remaining payment done":
          "Le document sera partagé uniquement après le paiement restant effectué",
        payRemainingAmount: "Payer le montant restant",
        billingAndInvoicing: "Facturation et facturation",
        "Pick an account plan that fits your workflow.":
          "Choisissez un plan de compte qui correspond à votre flux de travail.",
        invoice: "Facture",
        billingDate: "Date de facturation",
        status: "Statut",
        amount: "Montant",
        action: "Action",
        checkout: "Paiement",
        or: "Ou",
        createAnAccount: "Créer un compte",
        passwordsDidNotMatch: "Les mots de passe ne correspondent pas",
        accountCreatedSuccessfully: "Compte créé avec succès",
        pleaseEnterValidData: "Veuillez saisir des données valides !",
        problemWhileSigningUp:
          "Un problème est survenu lors de l'inscription !",
        docsUploadedSuccessfully: "Documents téléchargés avec succès !",
        pleaseUploadAllFiles:
          "Veuillez télécharger des fichiers pour tous les types de documents.",
        pleaseAgreeToTermsAndConditions:
          "Veuillez accepter les termes et conditions",
        pleaseEnterAllTheValues: "Veuillez saisir toutes les valeurs",
        "No worries, we'll send you reset instructions.":
          "Pas de soucis, nous vous enverrons des instructions de réinitialisation.",
        errorWhileSendingOtp:
          "Erreur lors de l'envoi de l'OTP, réessayez plus tard",
        pleaseEnterYourEmail: "Veuillez entrer l'e-mail.",
        welcomeBack: "Bienvenue de retour !",
      },
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
