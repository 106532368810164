import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import {
  Subscribe,
  BoostBusiness,
  FAQ,
  ChatWithProfessionals,
  PlanCard,
  GetInTouchFormSelect,
  NotSureWhatToDo,
} from "components";
import { useLocation, useNavigate } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import {
  creativeInnovationNetwork,
  internationalBusinessCenter,
  resilientBusinessClimate,
  talentRichWorkforce,
  zap,
  reservetradename,
  llcdubai,
  professionallicense,
  branchoffice,
  soleproprietorship,
  corporatebankaccount,
  teamwithreports,
  taxPhoto,
  licensePhoto,
  compliance,
  businessInsurance,
} from "assets";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const UKBusinessSetup = () => {
  const { t } = useTranslation();
  // const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const targetRef = useRef(null);

  const topReasons = [
    {
      id: 1,
      icon: internationalBusinessCenter,
      title: "International Business Center",
      description: "As a leading global business hub, the UK provides a wide range of opportunities for international trade and expansion.",
    },
    {
      id: 2,
      icon: creativeInnovationNetwork,
      title: "Creative Innovation Network",
      description: "With a strong emphasis on innovation and research, the UK is an ideal environment for businesses in the technology, finance, and healthcare sectors.",
    },
    {
      id: 3,
      icon: talentRichWorkforce,
      title: "Talent-Rich Workforce",
      description: "The UK offers access to a diverse pool of skilled professionals across multiple industries.",
    },
    {
      id: 4,
      icon: resilientBusinessClimate,
      title: "Resilient Business Climate",
      description: "Benefit from a stable political and economic environment, along with transparent legal frameworks, while conducting business.",
    },
  ];

  const businessStructure = [
    { id: 1, name: "Sole Trader", image: soleproprietorship },
    { id: 2, name: "Limited Company (Ltd)", image: branchoffice },
    { id: 3, name: "Partnership", image: professionallicense },
    { id: 4, name: "Limited Liability Partnership (LLP)", image: llcdubai }
  ]

  const pathways = [
    {
      id: 1,
      title: "Choosing a Business Structure",
      description: <>
        <ul>
          <li>Sole Trader</li>
          <li>Limited Company (Ltd)</li>
          <li>Partnership</li>
          <li>Limited Liability Partnership (LLP)</li>
        </ul>
      </>,
      image: (
        <Row className="row-cols-2">
          {businessStructure.map((businessActivity) => {
            return (
              <Col className="mb-4">
                <Card
                  className="p-4 d-flex justify-content-center align-items-center border-0"
                  style={{
                    height: 280,
                    backgroundColor: "#eff0fa",
                    borderRadius: 20,
                  }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#ffffff",
                      width: 150,
                      height: 150,
                      borderRadius: 75,
                    }}
                  >
                    <Image src={businessActivity.image} alt={businessActivity.name} className="animated-image" style={{ height: 100 }} />
                  </div>
                  <div className="subtitle text-center mt-4">{businessActivity.name}</div>
                </Card>
              </Col>
            )
          })}
        </Row>
      ),
    },
    {
      id: 2,
      title: "Company Registration",
      description: <>
        <ul>
          <li>Register with Companies House</li>
          <li>Choosing a Company Name</li>
          <li>Memorandum & Articles of Association</li>
          <li>Company Formation Services</li>
        </ul>
      </>,
      image: (
        <Image
          className="w-100"
          src={reservetradename}
          alt="Company Registration"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 3,
      title: "Tax Registration",
      description: <>
        <ul>
          <li>Register for Corporation Tax (if a Limited Company)</li>
          <li>Register for VAT (Value-Added Tax)</li>
          <li>PAYE Registration</li>
        </ul>
      </>,
      image: (
        <Image
          className="w-100"
          src={taxPhoto}
          alt="Tax Registration"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 4,
      title: "Opening a Business Bank Account",
      description: "A separate business bank account is essential for managing company finances and separating them from personal funds. Many UK banks offer accounts specifically for startups and small businesses.",
      image: (
        <Image
          className="w-100"
          src={corporatebankaccount}
          alt="Corporate Bank Account"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 5,
      title: "Obtaining Necessary Licenses and Permits",
      description: "Depending on your business activities, you may require licenses (e.g., for selling alcohol, food, or certain services).",
      image: (
        <Image className="w-100" src={licensePhoto} alt="Licenses and Permits" style={{ borderRadius: 20 }} />
      ),
    },
    {
      id: 6,
      title: "Compliance with UK Employment Laws",
      description: "If you plan to hire employees, you’ll need to comply with UK employment laws, which include offering the minimum wage, setting up a workplace pension, and adhering to health and safety regulations.",
      image: (
        <Image
          className="w-100"
          src={compliance}
          alt="Compliance"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 7,
      title: "Setting Up Business Insurance",
      description: "Certain insurances are required by law, such as Employers’ Liability Insurance if you have staff.",
      image: (
        <Image
          className="w-100"
          src={businessInsurance}
          alt="Business Insurance"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 8,
      title: "Record Keeping and Annual Filings",
      description: "Maintain accurate financial records, as you will need to file annual accounts with Companies House, pay Corporation Tax, and submit returns to HMRC.",
      image: (
        <Image
          className="w-100"
          src={teamwithreports}
          alt="Record Keeping"
          style={{ borderRadius: 20 }}
        />
      ),
    },
  ];

  const faqs = [
    // {
    //   id: 1,
    //   question: "How should I contact SKV Business Service for help regarding business setup in the UK?",
    //   answer: "Fill in the form at the top of the page and we'll give you a call as soon as possible. Or call us by clicking on the phone icon on the navigation bar"
    // },
    // {
    //   id: 2,
    //   question: "What help should I expect with documentation and paperwork for business setup in the UK?",
    //   answer: "SKV Business can assist with preparing all necessary paperwork and documentation for setting up your business in the UK. We handle the administrative tasks efficiently, allowing you to focus on growing your business."
    // },
    // {
    //   id: 3,
    //   question: "Which legal structures for business setup in the UK can SKV Business assist with?",
    //   answer: "In the UK, the legal business structures include sole trader, limited company, partnership and limited liability partnership companies. We assist you in selecting the most suitable structure for your business while ensuring compliance with UK regulations."
    // },
    // {
    //   id: 4,
    //   question: "What is the typical timeframe for completing the business setup process in the UK with SKV Business Service?",
    //   answer: "The time required for business setup can vary based on factors such as the type of company, regulatory requirements, and necessary documentation. At SKV Business Service, we prioritize efficiency and strive to expedite the process as much as possible."
    // },
    {
      id: 1,
      question: "What are the benefits of forming a limited company?",
      answer: "The primary advantage of setting up a limited company is the protection of limited liability, ensuring that your personal assets remain safe if the business faces financial difficulties. Additional benefits include increased credibility and greater tax efficiency."
    },
    {
      id: 2,
      question: "Do I need to be in the UK to form a company?",
      answer: "No, you don't need to be in the UK to form a company. As a non-UK resident, you can establish a company from most locations worldwide. However, you'll need a UK-registered office address, which 1st Formations includes in most of their company formation packages."
    },
    {
      id: 3,
      question: "How much time does it take to register a company?",
      answer: "With SKV Business, forming a company generally takes up to 24 hours. However, the time frame may vary depending on Companies House's workload for the day, and it could take longer."
    },
    {
      id: 4,
      question: "Is it possible to register a company by myself?",
      answer: "Yes, a single person can establish a limited company in the UK. You'll need at least one director, who can also serve as the company's shareholder (owner)."
    },
    {
      id: 5,
      question: "Is a business bank account necessary?",
      answer: "While there is no legal requirement for a limited company to open a business bank account, it is practically essential for operating effectively. Since a limited company is a separate legal entity from its owners, maintaining separate personal and business finances is crucial."
    },
  ]

  const [index, set] = useState(0);
  const [currentPathway, setCurrentPathway] = useState(0);
  const [service, setService] = useState(null);
  const [plans, setPlans] = useState([]);
  const [reqDocumentTypes, setReqDocumentTypes] = useState([]);

  const fetchService = async () => {
    const response = await actions.getService(param1);
    if (response) {
      setService(response);
    }
  };

  const fetchServicePlans = async () => {
    const response = await actions.getServicePlans(param1);
    if (response) {
      setPlans(response.results);
    }
  };

  const fetchServiceReqDocuments = async () => {
    const response = await actions.getServiceDocumentTypes(param1);
    if (response) {
      setReqDocumentTypes(response.results);
    }
  };

  const createSession = async (planId) => {
    const response = await actions.addSession({ plan: planId });
    if (response) {
      dispatch(actions.setSessionId(response.id));
      navigate(`/services/${param1}/plan`);
    }
  };

  useEffect(() => {
    if (param1) {
      fetchService();
      fetchServicePlans();
      fetchServiceReqDocuments();
    }
  }, [param1]);

  useEffect(() => {
    let timer = setTimeout(() => {
      set((state) => (state + 1) % 8);
      setCurrentPathway((state) => (state + 1) % 8);
    }, 5000);
    return () => clearTimeout(timer);
  }, [index]);

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container
          className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
            }`}
        >
          <div className="px-lg-3 text-primary body-text2">
            <b>{t("businessSetup")}</b>
          </div>
          <div className="page-header my-3">{service?.title}</div>
          <div className="subtitle-400 text-secondary text-lg-center mb-5">
            Make setting up your company in the UK quick and hassle-free with SKV Business Services.
          </div>
          <div
            ref={targetRef}
            className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
              }`}
          >
            {/* <Button
              className={`bg-primary text-white primary-button border border-primary poppins-600 ${large ? "" : "w-100"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/services/${param1}/plan`)}
            >
              {t("process")}
            </Button> */}
            <Button
              className={`bg-transparent text-primary poppins-600 ${large ? "" : "w-100 my-4"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/get-in-touch`)}
            >
              {t("getInTouch")}
            </Button>
          </div>
        </Container>
      </Container>
      <Container className="d-flex flex-column">
        <Row className={`mt-5 pt-5`}>
          <Col lg={7}>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                width: 80,
                height: 80,
                border: "12px solid #F9F5FF",
                borderRadius: 40,
                backgroundColor: "#F4EBFF",
              }}
            >
              <Image src={zap} alt="zap" style={{ width: 40, height: 40 }} />
            </div>
            <h4 className="my-3">
              Business Setup in the United Kingdom
            </h4>
            <div className="subtitle-400 text-secondary mb-3">
              Setting up a business in the United Kingdom offers numerous opportunities
              for growth and success. As a service provider, we streamline the process for
              entrepreneurs and businesses, ensuring compliance with all legal requirements.
              From company registration, tax setup, and obtaining necessary licenses, to
              opening business bank accounts and providing ongoing administrative support,
              we handle every aspect efficiently. Our goal is to make the UK business setup
              process as seamless as possible, allowing you to focus on growing your business
              while we take care of the paperwork and regulatory formalities.
            </div>
          </Col>
          <Col lg={1}></Col>
          <Col lg={4}>
            <Card className="mb-4" border="primary" style={{ borderRadius: 15, boxShadow: "8px 8px #2e3192" }}>
              <GetInTouchFormSelect />
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid className="bg-tertiary">
        <Container className="mb-5 pb-5">
          <div className="mt-5 pt-5 w-75">
            <div className="mt-5 text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>UK Business Setup</b>
            </div>
            <h3 className="my-2">Why the UK is Ideal for Business Expansion?</h3>
            <div className="subtitle-400 text-secondary mb-5">
              The UK offers numerous compelling reasons for being the top choice for
              business expansion. With its strong economy and excellent global
              connectivity, investors are drawn to the unique advantages it provides.
            </div>
          </div>
          <Row className="row-cols-2">
            {topReasons.map((reason, index) => {
              return (
                <Col className="mb-5">
                  <Card
                    className="p-4 d-flex justify-content-between align-items-center border-0"
                    style={{ height: 280 }}
                  >
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        width: 140,
                        height: 140,
                        border: "10px solid #F9F5FF",
                        borderRadius: 70,
                        backgroundColor: "#F4EBFF",
                      }}
                    >
                      <Image
                        src={reason.icon}
                        alt={reason.title}
                        style={index === 0 ? { width: 70, height: 70 } : { width: 80, height: 80 }}
                      />
                    </div>
                    <div className="subtitle mb-2">{reason.title}</div>
                    <div>
                      <div className="font-size16 text-secondary">
                        {reason.description}
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Container>
      <Container className="my-5 py-5">
        <div className="w-75">
          <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
            <b>{t("stepsAndRequirements")}</b>
          </div>
          <h3 className="my-2">{t("yourPathwayToBusinessSuccess")}</h3>
          <div className="subtitle-400 text-secondary mb-5">
            {t("exploreStepsAndRequirementsText")}
          </div>
        </div>
        <Row className="row-cols-4">
          <Col sm={6} className="">
            {pathways.map((pathway, index) => {
              return (
                <>
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderLeft:
                        currentPathway === index
                          ? "4px solid #2E3192"
                          : "4px solid #F2F4F7",
                    }}
                    onClick={() => setCurrentPathway(index)}
                  >
                    <div className="subtitle mb-2">{pathway.title}</div>
                    <div className="font-size16 text-secondary">
                      {pathway.description}
                    </div>
                  </div>
                  {currentPathway === index && !large && pathways[currentPathway].image}
                </>
              );
            })}
          </Col>
          {large && <Col sm={6}>
            <div className="sticky">
              {pathways[currentPathway].image}
            </div>
          </Col>}
        </Row>
      </Container>
      <NotSureWhatToDo expert="UK Business Setup" targetRef={targetRef} />
      {/* <Container fluid className="bg-tertiary py-5">
        <Container className="py-5 d-flex flex-column justify-content-center align-items-center">
          <div className="">
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("documents")}</b>
            </div>
            <h3 className="my-2">Plans that fit your scale</h3>
            <div className="subtitle-400 text-secondary mb-5">
              {t("letsStartWithDocumentation")}
            </div>
          </div>
          {plans.map((plan) => {
            return (
              <PlanCard
                plan={plan}
                reqDocumentTypes={reqDocumentTypes}
                serviceSlug={param1}
                createSession={createSession}
              />
            );
          })}
        </Container>
      </Container> */}
      <ChatWithProfessionals />
      <FAQ inputFaqs={faqs} />
      <BoostBusiness />
      <Subscribe />
    </>
  );
};

export default UKBusinessSetup;